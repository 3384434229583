import {inject, Injectable} from '@angular/core';
import {CognitoIdToken, CognitoUser} from 'amazon-cognito-identity-js';
import {from, lastValueFrom} from 'rxjs';
import {environment} from 'core/environments/environment';
import {userPool} from './auth.service';
import {UserService} from './user.service';
import {isServerResponse, kEmptyHttpStatusCodes, kErrorServerStatuses, kOKHttpStatusCodes, ServerError, ServerResponse, ServerStatus} from 'core/types';
import {Router} from '@angular/router';
import {revive} from '../../../../core/helpers';
import {CapacitorHttp, HttpResponse} from '@capacitor/core';
import {assertVersion} from '../../../../core/helpers/version';
import {packageVersion} from '../../../../core/version'
import {TranslateService} from '@ngx-translate/core';
import {AlertService} from './alert.service';
import {Platform} from '@ionic/angular';

type IRequestParams = Record<string, number | string | boolean | Array<string> | Array<number>> ;

@Injectable({providedIn: 'root'})
export class HttpService {
  url = '';

  protected userService = inject(UserService);
  protected router = inject(Router);
  protected translate = inject(TranslateService);
  protected alertService = inject(AlertService);
  protected platform = inject(Platform);

  constructor() {
    this.use(environment.server);
  }

  async getOut(message: string) {
    this.userService.logout();
    await this.router.navigateByUrl("/login");
  }

  revive(field: Date | string, onlyDate = true): Date {
    return revive(field, onlyDate);
  }

  dateStr(date: Date | string, hh?: number, mm?: number): string {
    const two = (i: number): string => (i < 10) ? "0" + i : "" + i;

    if (typeof date === 'string') return date;
    return date.getFullYear() + '-' + two(date.getMonth() + 1) + '-' + two(date.getDate()) + ' ' +
      two(hh || date.getHours()) + ':' + two(mm || date.getMinutes()) + ':' + two(date.getSeconds());
  }

  assertOK(httpResponse: HttpResponse): ServerResponse {
    // if the body is not json, we will throw with a server-error (500)
    // if status != OK, we will throw with a server-error (= resp.code)

    /*
    sample httpResonse: {
      body: {status: "ERROR", code: 401, message: "no valid token"},
      headers: {normalizedNames: {}, lazyUpdate: null},
      ok: true,
      status: 200,
      statusText: "OK",
      type: 4,
      url: "http://localhost:9229/api/contacts/list?q=e"
    }

    sample LambdaResponse: {
      clients: [{...}, {...}, ...}],
      code: 200,
      headers: {
        Server-Timing: "db;dur=7, app;dur=54035",
        X-Minimum-App-Version: "1.0.0"
      },
      message: "",
      status: "OK"
    }
    */

    const serverResponse = this.getServerResponse(httpResponse);

    if (kErrorServerStatuses.includes(serverResponse.status)) {
      throw new ServerError(
        serverResponse.message || serverResponse.status,
        serverResponse.statusCode || serverResponse.code || 500,
        serverResponse
      );
    }

    // check version if a minimal version is set
    if (!assertVersion(packageVersion, httpResponse.headers?.['x-minimum-app-version'])) {
      const err = new ServerError(serverResponse.headers?.['x-minimum-app-version'], 505);
      throw(err);
    }

    return serverResponse;
  }

  private getServerResponse(httpResponse: HttpResponse): ServerResponse {
    const headers: Record<string, string> = httpResponse.headers ? {...httpResponse.headers} : {};
    const body = httpResponse.data;
    if (!isServerResponse(body)) {
      const serverStatus = kOKHttpStatusCodes.includes(httpResponse.status) ? ServerStatus.kOK : ServerStatus.kError;
      const message = typeof body === 'string' ? body : '';
      return {
        status: serverStatus, message: message,
        headers, code: httpResponse.status, statusCode: httpResponse.status
      };
    }

    if (kEmptyHttpStatusCodes.includes(httpResponse.status)) {
      return {status: ServerStatus.kOK, statusCode: httpResponse.status, message: '', headers};
    }

    const lambdaResponse = body;
    lambdaResponse.headers ??= headers;
    return lambdaResponse;
  }

  async wrongVersion(version: string): Promise<ServerResponse> {
    const platform = this.platform.is('ios') ? 'ios' : (this.platform.is('android') ? 'android' : 'web');
    if (platform === 'web') {
      await this.alertService.presentAlert('Version', this.translate.instant("global.reload-" + platform), undefined, version);
      window.location.reload();
    } else this.router.navigateByUrl("/update");
    return {status: ServerStatus.kError, message: 'Please update your app to get the latest version'};
  }

  async executor(call: () => Promise<HttpResponse>): Promise<ServerResponse> {
    try {
      const response = await call();
      return this.assertOK(response);

    } catch (err: any) { // eslint-disable-line
      let error = err;
      if (error.status === 401) {
        // authentication failed -> get new access token

        try {
          // try to ask new accessToken and re-execute the call (headers will be reconstructed)
          await this.getNewAccessToken();
          const response = await call();
          return this.assertOK(response);

        } catch (err: any) { // eslint-disable-line
          // if the second call fails -> fail
          if (err.status === 401) {
            // either again: authentication failed -> logout in the UX too
            await this.getOut('HttpService.executor (2) -> message: 401 - ' + err.message);
            return {status: ServerStatus.kError, message: 'Session expired or wrong credentials'};

          } else if (err.status === 505) {
            return this.wrongVersion(err.message);

          } else {
            // normal call failure after second try
            await this.getOut('HttpService.executor (2) -> message: ' + err.status + ' - ' + err.message);
            error = err;
          }
        }
      } else if (error.status === 505) {
        return this.wrongVersion(error.message);

      } else {
        // normal call failure after 1st try
        // logger.log('http', 'HttpService.executor (1) -> http error: ' + error.status + ' - ' + error.message);
      }

      // normal call failure after first try
      if (error instanceof ServerError && error.response) {
        return error.response;
      } else {
        return {
          status: ServerStatus.kError,
          statusCode: error.status,
          code: error.status,
          message: error.statusText || error.message
        };
      }
    }
  }

  async getNewAccessToken(): Promise<CognitoIdToken> {
    const refreshToken = this.userService.getRefreshToken();
    const cognitoUser = new CognitoUser({Username: this.userService.getUserData()?.email || '', Pool: userPool});

    return new Promise((resolve, reject) => {
      cognitoUser.refreshSession(refreshToken, (error, session) => {
        if (error) {
          reject(error);
        } else {
          // call "storeTokens", not "login", we don't want to alert the rest of the application
          this.userService.storeTokens(session.idToken, session.accessToken, session.refreshToken);
          resolve(session.idToken);
        }
      });
    });
  }

  ////////////
  // Helper //
  ////////////
  /**
   * @param requestParams {param: value, param2: value2}
   * @returns ?param=value&param2=value2
   */
  private getRequestParamString(requestParams: IRequestParams): string {
    return Object.keys(requestParams).reduce((result, key) => {
      const param = requestParams[key];
      // number 0 & boolean false worden toegevoegd
      if (param !== null && param !== undefined && param !== '') {
        result += (result.length > 0) ? '&' : '?';
        if (typeof param === 'string') {
          result += `${key}=${encodeURIComponent(param)}`;
        } else {
          result += `${key}=${param as number}`;
        }
      }
      return result;
    }, '');
  }

  ////////////////
  // http calls //
  ////////////////

  use(url: string) {
    // remove trailing "/"
    this.url = (url[url.length-1] === '/') ? url.slice(0, -1) : url;
  }

  headers(contentType?: string, noAuth?: boolean) {
    const h: { [name: string]: string } = {'Cache-Control': 'no-cache, no-store'};
    if (!noAuth) {
      const token = this.userService.getAccessToken();

      if (token) {
        h['Authorization'] = /* "bearer " + */ token.getJwtToken();
      }
    }
    h['Content-Type'] = contentType || 'application/json';

    return h;
  }

  async post(path: string, body?: string | object, contentType?: string): Promise<ServerResponse> {
    return this.executor(
      () => lastValueFrom(from(CapacitorHttp.post({
          url: this.url + path,
          data: body,
          headers: this.headers(contentType),
          responseType: 'json'
      })
      ))
    );
  }

  async get(path: string, params?: string | IRequestParams): Promise<ServerResponse> {
    if (typeof params === "string") {
      path += '?' + params;
    } else if (typeof params != "undefined") {
      path += this.getRequestParamString(params)
    }

    return this.executor(
      () => lastValueFrom(from(CapacitorHttp.get({
          url: this.url + path,
          headers: this.headers(),
          responseType: 'json'
      })))
    );
  }

  async patch(path: string, body?: string | object, contentType?: string): Promise<ServerResponse> {
    return this.executor(
      () => lastValueFrom(from(CapacitorHttp.patch({
          url: this.url + path,
          data: body,
          headers: this.headers(contentType),
          responseType: 'json'
      })
      ))
    );
  }

  async put(path: string, body?: string | object, contentType?: string): Promise<ServerResponse> {
    return this.executor(
      () => lastValueFrom(from(CapacitorHttp.put({
          url: this.url + path,
          data: body,
          headers: this.headers(contentType),
          responseType: 'json'
      })
      ))
    );
  }

  async delete(path: string, params?: string | IRequestParams): Promise<ServerResponse> {
    if (typeof params === "string") {
      path += '?' + params;
    } else if (typeof params != "undefined") {
      path += this.getRequestParamString(params)
    }

    return this.executor(
      () => lastValueFrom(from(CapacitorHttp.delete({
          url: this.url + path,
          headers: this.headers(),
          responseType: 'json'
      })
      ))
    );
  }
}
