import {Injectable} from '@angular/core';
import {AuthResponse, IUser, UserResponse} from "core/models/user";
import {ServerResponse, ServerStatus} from "core/types";
import {
  AuthenticationDetails,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  ICognitoUserPoolData
} from "amazon-cognito-identity-js";
import {environment} from "core/environments/environment";
import {HttpService} from "./http.service";
import {awsMessage} from '../../../../core/helpers/aws-messages'
import {catchAwsError} from "core/helpers/aws-error-messages";

const poolData: ICognitoUserPoolData = {
  UserPoolId: environment.cognito.userPoolId,
  ClientId: environment.cognito.userPoolClientId
};
if (environment.cognito['endpoint']) {
  poolData.endpoint = environment.cognito['endpoint'];
}

export const userPool = new CognitoUserPool(poolData);
let cognitoUser: CognitoUser;

@Injectable({
  providedIn: 'root'
})
export class AuthService extends HttpService {
  async succes(result: CognitoUserSession) {
    const accessToken: CognitoAccessToken = result.getAccessToken();
    const idToken: CognitoIdToken = result.getIdToken();
    const refreshToken: CognitoRefreshToken = result.getRefreshToken();

    this.userService.login(idToken, accessToken, refreshToken);
    const user = {
      username: accessToken.payload['username'],
      token: idToken.getJwtToken(),
      expires: idToken.getExpiration()
    };
    this.userService.setUserData(user);
  }


  async login(email: string, password: string): Promise<AuthResponse | ServerResponse> {
    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password
    });

    const userData = {
      Username: email,
      Pool: userPool
    };

    cognitoUser = new CognitoUser(userData);
    if (environment.cognito['authenticationFlow']) {
      cognitoUser.setAuthenticationFlowType(environment.cognito['authenticationFlow']);
    }

    return new Promise<AuthResponse | ServerResponse>((resolve) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (session: CognitoUserSession) => {
          this.succes(session);
          resolve({status: ServerStatus.kOK});
        },

        totpRequired: (challengeName, challengeParameters) => {
          // we just keep the user in 'cognitoUser' and hope someone will call 'authChallenge'
          console.log("AuthService.login -> totpRequired: ", challengeName, challengeParameters);
          resolve({status: ServerStatus.kOK, code: "mfaRequired", message: challengeName});
        },

        newPasswordRequired(userAttributes) {
          delete userAttributes.email_verified
          cognitoUser.completeNewPasswordChallenge(password, userAttributes, this);
          resolve({status: ServerStatus.kOK});
        },

        onFailure: (err) => {
          console.log(err.message + " -> " + awsMessage(err.message));
          resolve({status: ServerStatus.kError, message: awsMessage(err.message)});
        }
      });
    });
  }

  async authChallenge(code: string): Promise<ServerResponse> {
    if (!cognitoUser) {
      return {status: ServerStatus.kError, message: awsMessage("User is not authenticated.")};

    } else {
      return new Promise((resolve) => {
        cognitoUser.sendMFACode(code, {
          onSuccess: (session: CognitoUserSession, _userConfirmationNecessary?: boolean) => {
            console.log("AuthService.authChallenge -> success");
            this.succes(session);
            resolve({status: ServerStatus.kOK});
          },
          onFailure: (err: Error) => {
            console.log("AuthService.authChallenge -> failure: ", err);
            resolve({status: ServerStatus.kError, message: awsMessage(err.message)});
          }
        }, 'SOFTWARE_TOKEN_MFA');
      });
    }
  }

  async getLoggedInUser(email: string, password: string): Promise<AuthResponse | ServerResponse> {
    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password
    });
    cognitoUser = new CognitoUser({Username: email, Pool: userPool});

    if (environment.cognito['authenticationFlow']) {
      cognitoUser.setAuthenticationFlowType(environment.cognito['authenticationFlow']);
    }

    return new Promise<AuthResponse | ServerResponse>((resolve) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (_session: CognitoUserSession) => {
          resolve({status: ServerStatus.kOK});
        },

        onFailure: (err) => {
          cognitoUser = null;
          resolve(catchAwsError(err));
        },

        totpRequired: (challengeName, challengeParameters) => {
          resolve({status: ServerStatus.kOK, code: "mfaRequired", message: challengeName});
        }
      });
    });
  }

  async fetch(): Promise<IUser> {
    // using the token in the header to select the user
    try {
      const resp = await this.get('/users/get') as UserResponse;
      if (resp.status === ServerStatus.kOK) {
        this.userService.setUserData(resp.user, resp.company, resp.employee);
        return resp.user;

      } else {
        console.error("AuthService.fetch -> returned error: ", resp.message);
      }

    } catch (e) {
      console.error("AuthService.fetch -> catched error: ", e);
      this.logout();
    }
    return null;
  }


  async logout() {
    const cognitoUser = userPool.getCurrentUser();
    // don't wait for Cognito to clear our token
    this.userService.logout();
    cognitoUser?.signOut();
  }

  async getDBUser(): Promise<UserResponse> {
    // using the token in the header to select the user
    return <Promise<UserResponse>>this.get('/users/get');
  }
}
