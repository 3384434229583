import {environment} from "./environments/environment";

export const _registerDataKey = environment.appId + '.REGISTER_DATA';
export const _fromMobileKey = environment.appId + '.FROM_MOBILE';
export const _isSignModusKey = environment.appId + '.IS_SIGNMODUS';
export const _clientHistoryViewActiveTabKey = environment.appId + '.CLIENT_HISTORYVIEW_ACTIVE_TAB';
export const _imageGalleryWidthKey = environment.appId + '.IMAGE_GALLERY_WIDTH';
export const _invoicesOverviewActiveTabKey = environment.appId + '.INVOICES_OVERVIEW_ACTIVE_TAB';
export const _invoicesSelectedSidebar = environment.appId + '.INVOICES_SELECTED_SIDEBAR';
export const _quotesOverviewActiveTabKey = environment.appId + '.QUOTES_OVERVIEW_ACTIVE_TAB';
export const _quotesSelectedSidebar = environment.appId + '.QUOTES_SELECTED_SIDEBAR';
export const _homePeriodLSKey = environment.appId + '.HOME.PERIOD';
export const _idTokenKey = environment.appId + '.ID_TOKEN';
export const _accessTokenKey = environment.appId + '.ACCESS_TOKEN';
export const _refreshTokenKey = environment.appId + '.REFRESH_TOKEN';
export const _userKey = environment.appId + '.USER';
export const _registerKey = environment.appId + '.REGISTER';
export const _accordionKey = environment.appId + '.ACCORDION';
export const _plannerChosenViewKey = environment.appId + '.PLANNER_CHOSEN_VIEW';
export const _documentsLineSidebarCategoryKey = environment.appId + '.DOCUMENTLINES_SIDEBAR_CATEGORY';
export const _sqlHistory = environment.appId + '.SQL_HISTORY';
export const _registrationFilterKey = environment.appId + '.REGISTRATION_FILTER';
export const _lastRegistration = environment.appId + '.REGISTRATION_LAST';
export const _plannerToPlanHeight = environment.appId + '.PLANNER_TO_PLAN_HEIGHT';
export const _plannerToPlanSelectedResources = environment.appId + '.PLANNER_TO_PLAN_SELECTED_RESOURCES';
export const _plannerToPlanOpen = environment.appId + '.PLANNER_TO_PLAN_OPEN';

export const _cardKey = environment.appId + '.CARD.'
