import {mapToCanActivate, mapToResolve, Routes} from '@angular/router';
import {IsNotAuthenticatedGuard} from "./guards/is-not-authenticated.guard";
import {IsAuthenticatedGuard} from "./guards/is-authenticated.guard";
import {UserDataResolver} from "./resolvers/user-data.resolver";

export const routes: Routes = [
  {
    path: 'login',
    loadComponent: () => import('./pages/start/login/login.page').then(m => m.LoginPage),
    canActivate: mapToCanActivate([IsNotAuthenticatedGuard])
  }, {
    path: 'mfa',
    loadComponent: () => import('./pages/start/mfa/mfa.page').then(m => m.MFAPage),
    canActivate: mapToCanActivate([IsNotAuthenticatedGuard])
  }, {
    path: 'about',
    loadComponent: () => import('./pages/start/about/about.page').then(m => m.AboutPage),
    canActivate: mapToCanActivate([IsNotAuthenticatedGuard])
  }, {
    path: 'update',
    loadComponent: () => import('./pages/start/update/update.page').then(m => m.UpdatePage)
  }, {
    path: 'maintenance',
    loadComponent: () => import('./pages/start/maintenance/maintenance.page').then(m => m.MaintenancePage)
  }, {
    path: 'app',
    loadChildren: () => import('./app/app.routes').then((m) => m.routes),
    canActivate: mapToCanActivate([IsAuthenticatedGuard]),
    resolve: mapToResolve(UserDataResolver)
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  }, {
    path: '**',
    redirectTo: '/login'
  }
];
