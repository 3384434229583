import {emptyMinimalAddress} from './address';

////////////////
// Interfaces //
import {IMinimalAddress} from '../models/address';
import {CancelReasons, DateTime, Id, Nullable, ServerResponse, YN} from '../types';
import {StripeId, StripeInvoice, StripePaymentMethod, StripePhase} from './stripe'
import {getRemainingSubscriptionDays} from '../helpers';
import {ICompanyLicensePlan, ISoftwareLicense, ISoftwareLicensePlan, LicenseType} from './licenses';
import {
  FeatureParam,
  Features,
  FeaturesParam,
  hasAnyFeature,
  hasFeature,
  PackedFeatures,
  unpackFeatures
} from './feature';

export enum CompanyType {
  kNone = '',
  kStudent = 'student',
  kCompany = 'company'
}

export interface CompanySubscription{
  subscription: StripeId;
  subscription_selected: Nullable<string | Date>;
  options: Array<StripeId>;
  phase: StripePhase;
  start: Nullable<string | Date>;
  end: Nullable<string | Date>;
  payment: StripePaymentMethod;
  customer: StripeId;
  plans?: Array<ICompanyLicensePlan>;
  upcoming?: Nullable<StripeInvoice>;
  cancel_reason?: Nullable<CancelReasons>;
  cancel_comment?: Nullable<string>;
}

export interface ICompanyBundleSubscriptionParams {
  readonly licensePlanId: Id;
  readonly numUserLicenses?: number;
}
export interface ICompanyOptionSubscriptionParams {
  readonly licensePlanId: Id;
  readonly quantity?: number;
}
export interface ICompanySubscriptionParams {
  readonly bundle: Nullable<ICompanyBundleSubscriptionParams>;
  readonly options?: ICompanyOptionSubscriptionParams[];
}

export interface ICompanySetSubscriptionOptions {
  firstLicense?: boolean;
  useTrialDays?: number;
}

export interface ICompany extends IMinimalAddress, Partial<CompanySubscription> {
  id: Id;
  name: string;
  vat_nr: string;
  vat_lookup_date?: Nullable<string | DateTime>;
  website: string;
  bic: string;
  iban: string;
  small: YN;

  phone: string;
  email: string;

  type: string;
  sector: string;
  referral: string;

  our_client?: number;

  features?: Features | PackedFeatures;
  maxstorage?: number;
  maxusers?: number;

  impersonate: YN;

  created?: string;
  modified?: string;
}


//////////////////////
// Server Responses //
export interface ICompanyResponse extends ServerResponse {
  company?: ICompany;
}

export interface VoucherResponse extends ServerResponse {
  valid: boolean;
}

export interface ICompanyFeatureResponse extends ServerResponse {
  features: Features;
}

export interface ICompanyUsageResponse extends ServerResponse {
  numExtraUsers?: number;
  numBytesUsed?: number;
}


///////////////
// Functions //
export function getEmptyCompany(): ICompany {
  return {
    id: 0,
    name: '',
    website: '',
    vat_nr: '',
    bic: '',
    iban: '',
    ...emptyMinimalAddress,
    phone: '',
    email: '',

    type: '',
    sector: '',
    referral: '',

    features: [],
    maxstorage: 0,
    maxusers: 0,

    subscription: '',
    options: [],
    phase: StripePhase.trial,
    impersonate: YN.kYes,
    end: null,
    payment: '',
    customer: '',
    small: YN.kNo
  }
}

export function getCurrentCompanyUserLicensesCount(subscription: CompanySubscription): number {
  return subscription?.plans?.reduce((acc, companyLicensePlan) => {
    const softwareLicensePlan = companyLicensePlan.licenseplan as ISoftwareLicensePlan;
    const softwareLicense = softwareLicensePlan.license as ISoftwareLicense;
    if (softwareLicense.type === LicenseType.user) acc += companyLicensePlan.quantity;
    return acc;
  }, 0) || 0;
}

export function getCurrentCompanyBundleLicensePlan(subscription: CompanySubscription): ICompanyLicensePlan | undefined {
  return getCurrentCompanyLicensePlansByType(subscription, LicenseType.bundle)[0];
}

export function getCurrentCompanyLicensePlansByType(subscription: CompanySubscription, type: LicenseType): ICompanyLicensePlan[] {
  return subscription?.plans?.filter((companyLicensePlan) => {
    const softwareLicensePlan = companyLicensePlan.licenseplan as ISoftwareLicensePlan;
    const softwareLicense = softwareLicensePlan.license as ISoftwareLicense;
    return softwareLicense.type === type;
  }) || [];
}

export function hasCompanyFeature(company: ICompany, feature: FeaturesParam, ...otherFeatures: FeatureParam[]): boolean {
  if (!company) return false;
  company.features = unpackFeatures(company.features);
  return hasFeature(company.features, feature, ...otherFeatures);
}

export function hasAnyCompanyFeature(company: ICompany, feature: FeaturesParam, ...otherFeatures: FeatureParam[]): boolean {
  if (!company) return false;
  company.features = unpackFeatures(company.features);
  return hasAnyFeature(company.features, feature, ...otherFeatures);
}

export function checkCompanySubscription(company: ICompany): boolean {
  const remainingDays = company.end ? getRemainingSubscriptionDays(company.end) : 0;
  const currentPhase = company.phase;
  const hasPayment = !!company.payment;

  if (!currentPhase) return false;

  if ([
    StripePhase.trial,
    StripePhase.paid,
    StripePhase.free,
    StripePhase.selecting // user is signing, has 1 day to select product and set up payment
  ].includes(currentPhase)) return true;

  if ([
    StripePhase.trialPastDue,
    StripePhase.pastDue,
  ].includes(currentPhase)) return hasPayment;


  // User didnt pay and all payment attempts failed//
  if (currentPhase === StripePhase.unpaid) return false;

  // NONE //
  if (currentPhase === StripePhase.none) return false;

  // STOPPED //
  if (currentPhase === StripePhase.stopped) {
    // Over end date
    return remainingDays >= 0;
  }

  // Default return false
  return false;
}
