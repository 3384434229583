import {inject, Injectable} from '@angular/core';
import {Router, UrlTree} from '@angular/router';
import {UserService} from '../services/user.service';
import {ToastService} from '../services/toast.service';

@Injectable({
  providedIn: 'root'
})
export class IsNotAuthenticatedGuard {
  private router = inject(Router);
  private userService = inject(UserService);
  private toastService = inject(ToastService);

  async canActivate(): Promise<boolean | UrlTree> {
    if (this.userService.isLoggedIn() && this.userService.getCompany() && this.userService.subscriptionOK()) return this.router.createUrlTree(['/app']);
    return true;
  }
}
