// Step 1: identify smallest unit in domain
// Step 2: define other units in terms of smallest
// Step 3: profit

import { roundNumberToDecimalDigits } from "./string";

export type ByteUnitName = keyof typeof ByteUnit;
export enum ByteUnit {
  B = 1,
  KB = ByteUnit.B * 1000,
  KiB = ByteUnit.B * 1024,
  MB = ByteUnit.KB * 1000,
  MiB = ByteUnit.KiB * 1024,
  GB = ByteUnit.MB * 1000,
  GiB = ByteUnit.MiB * 1024,
  TB = ByteUnit.GB * 1000,
  TiB = ByteUnit.GiB * 1024
}

export type TimeUnitName = keyof typeof TimeUnit;
export enum TimeUnit {
  ms = 1,
  s = TimeUnit.ms * 1000,
  min = TimeUnit.s * 60,
  h = TimeUnit.min * 60,
  d = TimeUnit.h * 24,
  w = TimeUnit.d * 7,
  mon = TimeUnit.d * 30.4375,
  y = TimeUnit.d * 365.25,

  // aliases
  millisecond = TimeUnit.ms,
  second = TimeUnit.s,
  minute = TimeUnit.min,
  hour = TimeUnit.h,
  day = TimeUnit.d,
  week = TimeUnit.w,
  month = TimeUnit.mon,
  year = TimeUnit.y
}

export type LengthUnitName = keyof typeof LengthUnit;
export enum LengthUnit {
  mm = 1,
  cm = LengthUnit.mm * 10,
  m = LengthUnit.cm * 100,
  km = LengthUnit.m * 1000,
  inch = LengthUnit.mm * 25.4,
  foot = LengthUnit.inch * 12,
  yard = LengthUnit.foot * 3,
  mile = LengthUnit.yard * 1760
}

export type AreaUnitName = keyof typeof AreaUnit;
export enum AreaUnit {
  mm2 = 1,
  cm2 = AreaUnit.mm2 * 100,
  m2 = AreaUnit.cm2 * 10000,
  km2 = AreaUnit.m2 * 1000000,
  inch2 = AreaUnit.mm2 * 645.16,
  foot2 = AreaUnit.inch2 * 144,
  yard2 = AreaUnit.foot2 * 9,
  acre = AreaUnit.yard2 * 4840,
  hectare = AreaUnit.m2 * 10000
}

export const Units = Object.freeze({
  Byte: ByteUnit,
  Time: TimeUnit,
  Length: LengthUnit,
  Area: AreaUnit
});

type UnitScale = number;

/**
 * Scales value from one unit to another unit in the same domain.
 * @example
 * scaleUnit(1, ByteUnit.TB, ByteUnit.GiB); // 931.3225746154785 GiB
 * scaleUnit(129, TimeUnit.minute, TimeUnit.hour); // 2.15 hours
 * scaleUnit(0.75, TimeUnit.year, TimeUnit.day); // 273.9375 days
 * scaleUnit(9.1452, ByteUnit.MB/TimeUnit.second, ByteUnit.KiB/TimeUnit.second) // 8930.859375 KiB/s
 * scaleUnit(4.34, ByteUnit.MiB/TimeUnit.second, ByteUnit.GiB/TimeUnit.hour) // 15.2578125 GiB/h
 * // etc...
 */
export function scaleUnit(value: number, from: UnitScale, to: UnitScale): number {
    let result = (value*from)/to;
    if (to === 1) result = Math.round(result);
    return result;
}

// Convenience functions for scaling specific units
export function scaleByteSize(value: number, from: ByteUnitName, to: ByteUnitName): number {
  return scaleUnit(value, ByteUnit[from], ByteUnit[to]);
}
export function scaleTime(value: number, from: TimeUnitName, to: TimeUnitName): number {
  return scaleUnit(value, TimeUnit[from], TimeUnit[to]);
}
export function scaleLength(value: number, from: LengthUnitName, to: LengthUnitName): number {
  return scaleUnit(value, LengthUnit[from], LengthUnit[to]);
}
export function scaleArea(value: number, from: AreaUnitName, to: AreaUnitName): number {
  return scaleUnit(value, AreaUnit[from], AreaUnit[to]);
}

interface IByteDisplayOptions {
  /**
   * Number of decimal digits to display
   * @default 2
   */
  decimalDigits?: number;
  /**
   * Automatically scale up to the largest unit that can represent the value
   * @default true
   */
  autoScaleUp?: boolean;
  /**
   * Scale down to the smallest unit that can represent the value if it is less than this threshold.
   * Use 0 to disable.
   * @default 0.1
   */
  scaleDownThreshold?: number;
}

// Convenience function for displaying a value with a unit
export function displayByteSize(value: number, unit: ByteUnitName, options?: IByteDisplayOptions): string;
export function displayByteSize(value: number, from: ByteUnitName, to: ByteUnitName, options?: IByteDisplayOptions): string;
export function displayByteSize(value: number, from: ByteUnitName, to?: ByteUnitName | IByteDisplayOptions, options?: IByteDisplayOptions): string {
  if (typeof to !== 'string') {
    if (typeof to === 'object') options = to;
    to = from;
  } else if (from !== to) {
    value = scaleByteSize(value, from, to);
    from = to;
  }
  const {autoScaleUp = true, scaleDownThreshold = 0.1, decimalDigits = 2} = options ?? {};
  const scaleFactor = (to.indexOf('i') > 0 || to.toUpperCase() === 'B') ? 1024 : 1000;
  if (autoScaleUp) {
    while (value > scaleFactor && ByteUnit[ByteUnit[to] * scaleFactor]) {
      to = ByteUnit[ByteUnit[to] * scaleFactor] as ByteUnitName;
      value = scaleByteSize(value, from, to);
      from = to;
    }
  }
  if (scaleDownThreshold > 0) {
    while (value < scaleDownThreshold && ByteUnit[ByteUnit[to] / scaleFactor]) {
      to = ByteUnit[ByteUnit[to] / scaleFactor] as ByteUnitName;
      value = scaleByteSize(value, from, to);
      from = to;
    }
  }
  return roundNumberToDecimalDigits(value, decimalDigits) + ' ' + to;
}
