import {APP_INITIALIZER, enableProdMode, ErrorHandler, importProvidersFrom} from '@angular/core';
import {bootstrapApplication} from '@angular/platform-browser';
import {provideRouter, Router, RouteReuseStrategy} from '@angular/router';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {routes} from './app/app.routes';
import {AppComponent} from './app/app.component';
import {environment} from 'core/environments/environment';
import {HttpClient, provideHttpClient} from "@angular/common/http";
import {provideIonicAngular} from "@ionic/angular/standalone";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {ScreenOrientation} from "@awesome-cordova-plugins/screen-orientation/ngx";
import * as Sentry from "@sentry/angular-ivy";
import {TraceService} from '@sentry/angular-ivy';
import {getStartOfDay} from '../../core/helpers/date';

if (environment.production || environment.staging) {
  Sentry.init({
    release: '8066-d7bf4b7647ce8579adccb415c8f8f515cda6085f', // should be replaced during the build
    dsn: "https://2f5adf4bf1b29439e3173aa546bbfb7f@o316258.ingest.us.sentry.io/4507729719394304",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

if (environment.production) {
  enableProdMode();
}

export function createTranslateLoader(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

bootstrapApplication(AppComponent, {
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    }, {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [TraceService],
      multi: true,
    },
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    provideIonicAngular(),
    provideHttpClient(),
    importProvidersFrom(TranslateModule.forRoot({
      defaultLanguage: 'nl-BE',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })),
    importProvidersFrom(IonicModule.forRoot({ innerHTMLTemplatesEnabled: true })),
    ScreenOrientation,
    provideRouter(routes),
  ]
});

// change to stringifier for the Date object to be accepted by the database
Date.prototype.toJSON = function () {
  return getStartOfDay(this).toISOString().replace('Z', '+00:00');
}
