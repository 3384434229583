import {inject, Injectable} from '@angular/core';
import {Router, UrlTree} from '@angular/router';
import {UserService} from "../services/user.service";
import {AuthService} from "../services/auth.service";
import {ToastService} from "../services/toast.service";

@Injectable({
  providedIn: 'root'
})
export class IsAuthenticatedGuard {
  private router = inject(Router);
  private userService = inject(UserService);
  private authService = inject(AuthService);
  private toastService = inject(ToastService);

  async canActivate(): Promise<boolean | UrlTree> {
    let result: boolean | UrlTree;
    try {
      // Fetch user and company data from the server (into the userService)
      await this.authService.fetch();

      if (this.userService.isLoggedIn()) {
        if (this.userService.getCompany() && this.userService.subscriptionOK()) {
          result = true;
        } else {
          result = this.router.createUrlTree(['/login']);
        }
      } else {
        result = this.router.createUrlTree(['/login']);
      }


    }  catch (e: any) {
      result = this.router.createUrlTree(['/login']);
    }
    return result
  }
}
