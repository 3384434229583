import {inject, Injectable} from '@angular/core';
import {AlertController, AlertInput} from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private alertController = inject(AlertController);
  private translateService = inject(TranslateService);

  public async presentAlert(header: string, message: string, buttons: string[] = ['OK'], subHeader?: string) {
    const alert = await this.alertController.create({
      header,
      message,
      subHeader,
      buttons
    });
    await alert.present();
    return await alert.onDidDismiss();
  }

  public async presentYnAlert(header: string): Promise<any> {
    const alert = await this.alertController.create({
      backdropDismiss: false,
      keyboardClose: false,
      header: this.translateService.instant('alerts.' + header),
      buttons: [{
        text: this.translateService.instant('global.no'),
        role: 'cancel',
      }, {
        text: this.translateService.instant('global.yes'),
        role: 'confirm',
      }]
    });

    await alert.present();
    return await alert.onDidDismiss();
  }

  public async presentInputAlert(header: string, inputs: AlertInput[], confirmButton?: string): Promise<any> {
    const alert = await this.alertController.create({
      backdropDismiss: false,
      keyboardClose: false,
      header: this.translateService.instant(header),
      inputs,
      buttons: [{
        text: this.translateService.instant('global.cancel'),
        role: 'cancel',
      }, {
        text: this.translateService.instant(confirmButton ? confirmButton : 'global.add'),
        role: 'confirm',
      }]
    });
    await alert.present();

    return await alert.onDidDismiss();
  }
}
