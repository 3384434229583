import {inject, Injectable} from '@angular/core';
import {ToastController} from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  private toastController = inject(ToastController)
  private translateService = inject(TranslateService);

  /**
   * Present a toast message
   * @param message The message to display
   * @param color "primary" , "secondary" , "tertiary" , "success" , "warning" , "danger" , "light" , "medium" , "dark"
   * @param duration The duration of the toast in milliseconds: default 2000
   * @param position "top" , "bottom" , "middle": default "bottom"
   * @param icon Icon to display at beginning of the toast
   */
  async presentToast(message: string,
                     color: string = 'primary',
                     duration?: number,
                     position?: 'top' | 'bottom' | 'middle',
                     icon?: string) {

    const toast = await this.toastController.create({
      message: this.translateService.instant(message),
      color,
      duration: duration || 3000,
      position: position || 'bottom',
      icon
    });
    await toast.present();
  }
}
