import {ISettingDefault, SettingCategories, SettingNames, SettingsInputType, SupportedLanguages} from './setting';
import {YN} from "../types";

const QuoteSettingsConfig: ISettingDefault[] = [{
  category: SettingCategories.quote,
  name: SettingNames.quote_prefix,
  default: '',
  input: SettingsInputType.text,
  language: '*'
}, {
  category: SettingCategories.quote,
  name: SettingNames.quote_valid,
  default: 15,
  input: SettingsInputType.number,
  language: '*'
}, {
  category: SettingCategories.quote,
  name: SettingNames.quote_footer_note,
  hint: ``,
  tooltip: `U kunt deze opmerking nog steeds uitschakelen op het niveau van de offerte.`,
  default: ``,
  input: SettingsInputType.editor,
  language: SupportedLanguages.nl
}, {
  category: SettingCategories.quote,
  name: SettingNames.quote_footer_note,
  hint: ``,
  tooltip: `Vous pouvez toujours désactiver cette note au niveau du devis.`,
  default: ``,
  input: SettingsInputType.editor,
  language: SupportedLanguages.fr
}, {
  category: SettingCategories.quote,
  name: SettingNames.quote_footer_note,
  hint: ``,
  tooltip: `You can still disable this note at the quotation level.`,
  default: ``,
  input: SettingsInputType.editor,
  language: SupportedLanguages.en
},

{ /* BTW verlegd medecontractant*/
  category: SettingCategories.quote,
  name: SettingNames.quote_with_0perc_vat_reverse_shifted,
  hint: ``,
  tooltip: `U bent wettelijk verplicht deze tekst weer te geven op offertes waar 0% BTW wordt gegeven.`,
  default: `Verlegging van heffing. Bij gebrek aan schriftelijke betwisting binnen een termijn van één maand na de ontvangst van de factuur, wordt de afnemer geacht te erkennen dat hij een belastingplichtige is gehouden tot de indiening van periodieke aangiften. Als die voorwaarde niet vervuld is, is de afnemer ten aanzien van die voorwaarde aansprakelijk voor de betaling van de verschuldigde belasting, interesten en geldboeten.`,
  input: SettingsInputType.readonly,
  language: SupportedLanguages.nl
}, {
  category: SettingCategories.quote,
  name: SettingNames.quote_with_0perc_vat_reverse_shifted,
  hint: ``,
  tooltip: `Vous êtes tenu par la loi d'afficher ce texte sur les devis où la TVA est de 0%.`,
  default: `L'autoliquidation. En l'absence de contestation écrite dans un délai d'un mois à compter de la réception de la facture, le client est réputé reconnaître sa qualité d'assujetti à la déclaration périodique. Si cette condition n'est pas remplie, le client est responsable du paiement de la taxe, des intérêts et des amendes dus au titre de cette condition.`,
  input: SettingsInputType.readonly,
  language: SupportedLanguages.fr
}, {
  category: SettingCategories.quote,
  name: SettingNames.quote_with_0perc_vat_reverse_shifted,
  hint: ``,
  tooltip: `You are legally obliged to display this text on quotations where 0% VAT is applied.`,
  default: `Reverse charge. In the absence of a written dispute within a period of one month from the receipt of the invoice, the customer shall be deemed to acknowledge that he is a taxpayer liable to submit periodic returns. If that condition is not fulfilled, the buyer shall be liable for the payment of the tax, interest and fines due with respect to that condition.`,
  input: SettingsInputType.readonly,
  language: SupportedLanguages.en
},

{ /* BTW verlegd intracommunautair (voor klanten in buitenland die een btw nummer hebben)*/
  category: SettingCategories.quote,
  name: SettingNames.quote_with_0perc_vat_reverse_intra,
  hint: ``,
  tooltip: `U bent wettelijk verplicht deze tekst weer te geven op offertes waar 0% BTW wordt gegeven.`,
  default: `- Intracommunautair voor diensten: “Btw verlegd: Art 21 § 2 van het Belgische btw-wetboek”
  - Indien goederen en diensten: “Verleggingsregeling – artikel 196 van de btw-richtlijn 2006/112/EG.”`,
  input: SettingsInputType.readonly,
  language: SupportedLanguages.nl
}, {
  category: SettingCategories.quote,
  name: SettingNames.quote_with_0perc_vat_reverse_intra,
  hint: ``,
  tooltip: `Vous êtes tenu par la loi d'afficher ce texte sur les devis où la TVA est de 0%.`,
  default: `- Intracommunautaire pour les services : "TVA reportée : Article 21 § 2 du Code TVA belge."
  - Intracommunity for services: "VAT shifted: Article 21 § 2 of the Belgian VAT Code." - If goods and services: "Reverse charge mechanism – Article 196 of VAT Directive 2006/112/EC."`,
  input: SettingsInputType.readonly,
  language: SupportedLanguages.fr
}, {
  category: SettingCategories.quote,
  name: SettingNames.quote_with_0perc_vat_reverse_intra,
  hint: ``,
  tooltip: `You are legally obliged to display this text on quotations where 0% VAT is applied.`,
  default: `- Intracommunity for services: "VAT shifted: Article 21 § 2 of the Belgian VAT Code."
  - If goods and services: "Reverse charge mechanism – Article 196 of VAT Directive 2006/112/EC."`,
  input: SettingsInputType.readonly,
  language: SupportedLanguages.en
},

{ /* 0% BTW tarief voor recuperatiegoederen*/
  category: SettingCategories.quote,
  name: SettingNames.quote_with_0perc_vat_reverse_normal,
  hint: ``,
  tooltip: ``,
  default: `Recuperatieproducten vrijgesteld van BTW volgens circulaire nº 68 van 15/12/70 - Attest voorzien`,
  input: SettingsInputType.readonly,
  language: SupportedLanguages.nl
}, {
  category: SettingCategories.quote,
  name: SettingNames.quote_with_0perc_vat_reverse_normal,
  hint: ``,
  tooltip: ``,
  default: `Produits de récupération exemptés de la TVA selon la circulaire nº 68 du 15/12/70 - Attestation fournie.`,
  input: SettingsInputType.readonly,
  language: SupportedLanguages.fr
}, {
  category: SettingCategories.quote,
  name: SettingNames.quote_with_0perc_vat_reverse_normal,
  hint: ``,
  tooltip: ``,
  default: `Recovery products exempt from VAT according to circular no. 68 of 15/12/70 - Certificate provided.`,
  input: SettingsInputType.readonly,
  language: SupportedLanguages.en
},

{ /* BTW vrijgesteld */
  category: SettingCategories.quote,
  name: SettingNames.quote_with_0perc_vat_exempt,
  hint: ``,
  tooltip: `U bent wettelijk verplicht deze tekst weer te geven op offertes waar 0% BTW wordt gegeven.`,
  default: `Kleine onderneming onderworpen aan de vrijstellingsregeling van belasting. Btw niet toepasselijk.`,
  input: SettingsInputType.readonly,
  language: SupportedLanguages.nl
}, {
  category: SettingCategories.quote,
  name: SettingNames.quote_with_0perc_vat_exempt,
  hint: ``,
  tooltip: `Vous êtes tenu par la loi d'afficher ce texte sur les devis où la TVA est de 0%.`,
  default: `Petite entreprise soumise à un régime d'exonération fiscale. TVA non applicable.`,
  input: SettingsInputType.readonly,
  language: SupportedLanguages.fr
}, {
  category: SettingCategories.quote,
  name: SettingNames.quote_with_0perc_vat_exempt,
  hint: ``,
  tooltip: `You are legally obliged to display this text on quotations where 0% VAT is applied.`,
  default: `Small business subject to tax exemption scheme. VAT not applicable.`,
  input: SettingsInputType.readonly,
  language: SupportedLanguages.en
},

{
  category: SettingCategories.quote,
  name: SettingNames.quote_with_6perc_vat,
  hint: ``,
  tooltip: `U bent wettelijk verplicht deze tekst weer te geven op offertes waar 6% BTW wordt gegeven bij renovatie van woningen.`,
  default: `Btw-tarief 6%: Bij gebrek aan een schriftelijke betwisting binnen een termijn van één maand vanaf de ontvangst van de factuur, wordt de klant geacht te erkennen dat
  (1) de werken worden verricht aan een woning waarvan de eerste ingebruikneming heeft plaatsgevonden in een kalenderjaar dat ten minste tien/vijftien jaar voorafgaat aan de datum van de eerste factuur met betrekking tot die werken,
  (2) de woning, na uitvoering van die werken, uitsluitend of hoofdzakelijk als privéwoning gebruikt wordt en
  (3) de werken worden verstrekt en gefactureerd aan een eindverbruiker. Wanneer minstens één van die voorwaarden niet is voldaan, zal de afnemer binnen een termijn van één maand de toepassing van het verlaagde btw-tarief schriftelijk moeten betwisten en zal de aannemer gehouden zijn om een corrigerende factuur of debet nota uit te reiken aan de afnemer.`,
  input: SettingsInputType.readonly,
  language: SupportedLanguages.nl
}, {
  category: SettingCategories.quote,
  name: SettingNames.quote_with_6perc_vat,
  hint: ``,
  tooltip: `La loi vous oblige à afficher ce texte sur les devis où une TVA de 6 % est facturée en raison de la rénovation de la maison.`,
  default: `Taux de TVA 6%: En l'absence de contestation écrite dans un délai d'un mois à compter de la réception de la facture, le client est réputé reconnaître que
  (1) les travaux sont effectués sur un logement dont la première occupation a eu lieu au cours d'une année civile précédant d'au moins dix/quinze ans la date de la première facture relative à ces travaux,
  (2) le logement, après l'exécution de ces travaux, est utilisé exclusivement ou principalement comme logement privé, et
  (3) les travaux sont fournis et facturés à un consommateur final. Si au moins une de ces conditions n'est pas remplie, le client devra contester l'application du taux réduit de TVA par écrit dans un délai d'un mois et l'entrepreneur sera tenu d'émettre une facture rectificative ou une note de débit au client.`,
  input: SettingsInputType.readonly,
  language: SupportedLanguages.fr
}, {
  category: SettingCategories.quote,
  name: SettingNames.quote_with_6perc_vat,
  hint: ``,
  tooltip: `You are required by law to display this text on quotations where 6% VAT is charged due to home renovation.`,
  default: `VAT rate 6%: In the absence of a written dispute within a period of one month from receipt of the invoice, the customer shall be deemed to acknowledge that
   (1) the works are performed on a dwelling whose first occupancy occurred in a calendar year that is at least ten/fifteen years prior to the date of the first invoice relating to those works,
   (2) the dwelling, after performance of those works, is used exclusively or primarily as a private residence, and
   (3) the works are provided and billed to a final consumer. If at least one of these conditions is not met, the customer will have to dispute the application of the reduced VAT rate in writing within a period of one month and the contractor will be required to issue a corrective invoice or debit note to the customer.`,
  input: SettingsInputType.readonly,
  language: SupportedLanguages.en
}];

const InvoiceSettingsConfig: ISettingDefault[] = [{
  category: SettingCategories.invoice,
  name: SettingNames.invoice_prefix,
  default: '',
  input: SettingsInputType.text,
  language: '*'
}, {
  category: SettingCategories.invoice,
  name: SettingNames.invoice_valid,
  default: 15,
  input: SettingsInputType.number,
  language: '*'
}, {
  category: SettingCategories.invoice,
  name: SettingNames.invoice_ogm,
  default: YN.kNo,
  input: SettingsInputType.checkbox,
  language: '*',
  tooltip: 'settings.general.invoice_ogm_tooltip'
}, {
  category: SettingCategories.invoice,
  name: SettingNames.invoice_footer_note,
  hint: ``,
  tooltip: `U kunt deze nota nog steeds uitschakelen op factuurniveau.`,
  default: ``,
  input: SettingsInputType.editor,
  language: SupportedLanguages.nl
}, {
  category: SettingCategories.invoice,
  name: SettingNames.invoice_footer_note,
  hint: ``,
  tooltip: `Vous pouvez toujours désactiver cette note au niveau de la facture.`,
  default: ``,
  input: SettingsInputType.editor,
  language: SupportedLanguages.fr
}, {
  category: SettingCategories.invoice,
  name: SettingNames.invoice_footer_note,
  hint: ``,
  tooltip: `You can still disable this note at invoice level.`,
  default: ``,
  input: SettingsInputType.editor,
  language: SupportedLanguages.en

}, { /* BTW verlegd */
  category: SettingCategories.invoice,
  name: SettingNames.invoice_with_0perc_vat_reverse_shifted,
  hint: ``,
  tooltip: `U bent wettelijk verplicht deze tekst weer te geven op facturen waar 0% BTW wordt gegeven.`,
  default: `Verlegging van heffing. Bij gebrek aan schriftelijke betwisting binnen een termijn van één maand na de ontvangst van de factuur, wordt de afnemer geacht te erkennen dat hij een belastingplichtige is gehouden tot de indiening van periodieke aangiften. Als die voorwaarde niet vervuld is, is de afnemer ten aanzien van die voorwaarde aansprakelijk voor de betaling van de verschuldigde belasting, interesten en geldboeten.`,
  input: SettingsInputType.readonly,
  language: SupportedLanguages.nl
}, {
  category: SettingCategories.invoice,
  name: SettingNames.invoice_with_0perc_vat_reverse_shifted,
  hint: ``,
  tooltip: `Vous êtes tenu par la loi d'afficher ce texte sur les factures où la TVA est de 0%.`,
  default: `L'autoliquidation. En l'absence de contestation écrite dans un délai d'un mois à compter de la réception de la facture, le client est réputé reconnaître sa qualité d'assujetti à la déclaration périodique. Si cette condition n'est pas remplie, le client est responsable du paiement de la taxe, des intérêts et des amendes dus au titre de cette condition.`,
  input: SettingsInputType.readonly,
  language: SupportedLanguages.fr
}, {
  category: SettingCategories.invoice,
  name: SettingNames.invoice_with_0perc_vat_reverse_shifted,
  hint: ``,
  tooltip: `You are legally obliged to display this text on invoices where 0% VAT is applied.`,
  default: `Reverse charge. In the absence of a written dispute within a period of one month from the receipt of the invoice, the customer shall be deemed to acknowledge that he is a taxpayer liable to submit periodic returns. If that condition is not fulfilled, the buyer shall be liable for the payment of the tax, interest and fines due with respect to that condition.`,
  input: SettingsInputType.readonly,
  language: SupportedLanguages.en
},


{ /* BTW verlegd intracommunautair (voor klanten in buitenland die een btw nummer hebben)*/
  category: SettingCategories.invoice,
  name: SettingNames.invoice_with_0perc_vat_reverse_intra,
  hint: ``,
  tooltip: `U bent wettelijk verplicht deze tekst weer te geven op offertes waar 0% BTW wordt gegeven.`,
  default: `- Intracommunautair voor diensten: “Btw verlegd: Art 21 § 2 van het Belgische btw-wetboek”
- Indien goederen en diensten: “Verleggingsregeling – artikel 196 van de btw-richtlijn 2006/112/EG.”`,
  input: SettingsInputType.readonly,
  language: SupportedLanguages.nl
}, {
  category: SettingCategories.invoice,
  name: SettingNames.invoice_with_0perc_vat_reverse_intra,
  hint: ``,
  tooltip: `Vous êtes tenu par la loi d'afficher ce texte sur les devis où la TVA est de 0%.`,
  default: `- Intracommunautaire pour les services : "TVA reportée : Article 21 § 2 du Code TVA belge."
- Intracommunity for services: "VAT shifted: Article 21 § 2 of the Belgian VAT Code." - If goods and services: "Reverse charge mechanism – Article 196 of VAT Directive 2006/112/EC."`,
  input: SettingsInputType.readonly,
  language: SupportedLanguages.fr
}, {
  category: SettingCategories.invoice,
  name: SettingNames.invoice_with_0perc_vat_reverse_intra,
  hint: ``,
  tooltip: `You are legally obliged to display this text on quotations where 0% VAT is applied.`,
  default: `- Intracommunity for services: "VAT shifted: Article 21 § 2 of the Belgian VAT Code."
- If goods and services: "Reverse charge mechanism – Article 196 of VAT Directive 2006/112/EC."`,
  input: SettingsInputType.readonly,
  language: SupportedLanguages.en
},

{ /* 0% BTW tarief voor recuperatiegoederen*/
  category: SettingCategories.invoice,
  name: SettingNames.invoice_with_0perc_vat_reverse_normal,
  hint: ``,
  tooltip: ``,
  default: `Recuperatieproducten vrijgesteld van BTW volgens circulaire nº 68 van 15/12/70 - Attest voorzien`,
  input: SettingsInputType.readonly,
  language: SupportedLanguages.nl
}, {
  category: SettingCategories.invoice,
  name: SettingNames.invoice_with_0perc_vat_reverse_normal,
  hint: ``,
  tooltip: ``,
  default: `Produits de récupération exemptés de la TVA selon la circulaire nº 68 du 15/12/70 - Attestation fournie.`,
  input: SettingsInputType.readonly,
  language: SupportedLanguages.fr
}, {
  category: SettingCategories.invoice,
  name: SettingNames.invoice_with_0perc_vat_reverse_normal,
  hint: ``,
  tooltip: ``,
  default: `Recovery products exempt from VAT according to circular no. 68 of 15/12/70 - Certificate provided.`,
  input: SettingsInputType.readonly,
  language: SupportedLanguages.en
},

{ /* BTW vrijgesteld */
  category: SettingCategories.invoice,
  name: SettingNames.invoice_with_0perc_vat_exempt,
  hint: ``,
  tooltip: `U bent wettelijk verplicht deze tekst weer te geven op offertes waar 0% BTW wordt gegeven.`,
  default: `Kleine onderneming onderworpen aan de vrijstellingsregeling van belasting. Btw niet toepasselijk.`,
  input: SettingsInputType.readonly,
  language: SupportedLanguages.nl
}, {
  category: SettingCategories.invoice,
  name: SettingNames.invoice_with_0perc_vat_exempt,
  hint: ``,
  tooltip: `Vous êtes tenu par la loi d'afficher ce texte sur les devis où la TVA est de 0%.`,
  default: `Petite entreprise soumise à un régime d'exonération fiscale. TVA non applicable.`,
  input: SettingsInputType.readonly,
  language: SupportedLanguages.fr
}, {
  category: SettingCategories.invoice,
  name: SettingNames.invoice_with_0perc_vat_exempt,
  hint: ``,
  tooltip: `You are legally obliged to display this text on quotations where 0% VAT is applied.`,
  default: `Small business subject to tax exemption scheme. VAT not applicable.`,
  input: SettingsInputType.readonly,
  language: SupportedLanguages.en
},

{ /* BTW 6% */
  category: SettingCategories.invoice,
  name: SettingNames.invoice_with_6perc_vat,
  hint: ``,
  tooltip: `U bent wettelijk verplicht deze tekst weer te geven op offertes waar 6% BTW wordt gegeven bij renovatie van woningen.`,
  default: `Btw-tarief 6%: Bij gebrek aan een schriftelijke betwisting binnen een termijn van één maand vanaf de ontvangst van de factuur, wordt de klant geacht te erkennen dat
    (1) de werken worden verricht aan een woning waarvan de eerste ingebruikneming heeft plaatsgevonden in een kalenderjaar dat ten minste tien/vijftien jaar voorafgaat aan de datum van de eerste factuur met betrekking tot die werken,
    (2) de woning, na uitvoering van die werken, uitsluitend of hoofdzakelijk als privéwoning gebruikt wordt en
    (3) de werken worden verstrekt en gefactureerd aan een eindverbruiker. Wanneer minstens één van die voorwaarden niet is voldaan, zal de afnemer binnen een termijn van één maand de toepassing van het verlaagde btw-tarief schriftelijk moeten betwisten en zal de aannemer gehouden zijn om een corrigerende factuur of debet nota uit te reiken aan de afnemer.`,
  input: SettingsInputType.readonly,
  language: SupportedLanguages.nl
}, {
  category: SettingCategories.invoice,
  name: SettingNames.invoice_with_6perc_vat,
  hint: ``,
  tooltip: `La loi vous oblige à afficher ce texte sur les devis où une TVA de 6 % est facturée en raison de la rénovation de la maison.`,
  default: `Taux de TVA 6%: En l'absence de contestation écrite dans un délai d'un mois à compter de la réception de la facture, le client est réputé reconnaître que
  (1) les travaux sont effectués sur un logement dont la première occupation a eu lieu au cours d'une année civile précédant d'au moins dix/quinze ans la date de la première facture relative à ces travaux,
  (2) le logement, après l'exécution de ces travaux, est utilisé exclusivement ou principalement comme logement privé, et
  (3) les travaux sont fournis et facturés à un consommateur final. Si au moins une de ces conditions n'est pas remplie, le client devra contester l'application du taux réduit de TVA par écrit dans un délai d'un mois et l'entrepreneur sera tenu d'émettre une facture rectificative ou une note de débit au client.`,
  input: SettingsInputType.readonly,
  language: SupportedLanguages.fr
}, {
  category: SettingCategories.invoice,
  name: SettingNames.invoice_with_6perc_vat,
  hint: ``,
  tooltip: `You are required by law to display this text on quotations where 6% VAT is charged due to home renovation.`,
  default: `VAT rate 6%: In the absence of a written dispute within a period of one month from receipt of the invoice, the customer shall be deemed to acknowledge that
  (1) the works are performed on a dwelling whose first occupancy occurred in a calendar year that is at least ten/fifteen years prior to the date of the first invoice relating to those works,
  (2) the dwelling, after performance of those works, is used exclusively or primarily as a private residence, and
  (3) the works are provided and billed to a final consumer. If at least one of these conditions is not met, the customer will have to dispute the application of the reduced VAT rate in writing within a period of one month and the contractor will be required to issue a corrective invoice or debit note to the customer.`,
  input: SettingsInputType.readonly,
  language: SupportedLanguages.en
}];

const InvoiceMailSettings: ISettingDefault[] = [{
  category: SettingCategories.invoicemail,
  name: SettingNames.invoice_mail_title,
  default: `[type]: [title] - [nr]`,
  hint: `U kan volgende elementen gebruiken in uw titel, die dan bij het versturen van de mail door ons vervangen worden:
  - [type]: Bij facturen/creditnota.
  - [title]: de titel van je offerte, factuur of credit nota.
  - [nr]: het document nummer, bvb: fact/0034, waar "fact/" voorvoegsel is (instelbaar in Instellingen > Facturen > Algemeen - facturen > Factuur voorvoegsel)
  maar ook alle andere elementen hieronder vermeld.`,
  input: SettingsInputType.text,
  language: SupportedLanguages.nl
},
  {
    category: SettingCategories.invoicemail,
    name: SettingNames.invoice_mail_pdf_body,
    hint: `U kan volgende elementen gebruiken in uw tekst, die dan bij het versturen van de mail door ons vervangen worden:
  - [clientname]: De naam van de klant.
  - [type]: Bij facturen/creditnota.
  - [nr]: het document nummer, bvb: fact/0034, waar "fact/" voorvoegsel is (instelbaar in Instellingen > Facturen > Algemeen - facturen > Factuur voorvoegsel)
  - [date_sent]: de datum waarop jij het document verstuurt.
  - [document_date]: de datum van het document.
  - [title]: de titel van je factuur of credit nota.
  - [total]: het totaal van je factuur of credit nota.
  - [expiry_date]: de vervaldatum van het verstuurde document.
  - [username]: jouw naam.
  - [companyname]: de naam van je bedrijf (instelbaar bij Instellingen > Bedrijfsgegevens > Bedrijfsnaam).
  - [email]: het e-mailadres van je bedrijf (instelbaar bij Instellingen > Bedrijfsgegevens > Email).`,
    default: `<p>Beste [clientname], </p><p>Bedankt voor het vertrouwen in ons!</p>
  <p><br></p>
  <p>Bijgevoegd in deze e-mail vind je de [type] [nr] van [document_date] met betrekking tot [title].</p>
  <p>Het totaal bedrag is [total]€ en vervalt op [expiry_date].</p>
  <p><br></p>
  <p>Indien je vragen of bezorgdheden hebt over deze [type], aarzel niet om ons te contacteren via [email]</p>
  <p><br></p>
  <p>Vriendelijke groet,</p>
  <p>[username]<br>[companyname]</p>`,
    input: SettingsInputType.editor,
    language: SupportedLanguages.nl
  },
  {
    category: SettingCategories.invoicemail,
    name: SettingNames.invoice_mail_hub_body,
    hint: `U kan volgende elementen gebruiken in uw tekst, die dan bij het versturen van de mail door ons vervangen worden:
  - [clientname]: De naam van de klant.
  - [type]: Bij facturen/creditnota.
  - [nr]: het document nummer, bvb: fact/0034, waar "fact/" voorvoegsel is (instelbaar in Instellingen > Facturen > Algemeen - facturen > Factuur voorvoegsel)
  - [date_sent]: de datum waarop jij het document verstuurt.
  - [document_date]: de datum van het document.
  - [title]: de titel van je factuur of credit nota.
  - [total]: het totaal van je factuur of credit nota.
  - [expiry_date]: de vervaldatum van het verstuurde document.
  - [username]: jouw naam.
  - [companyname]: de naam van je bedrijf (instelbaar bij Instellingen > Bedrijfsgegevens > Bedrijfsnaam).
  - [email]: het e-mailadres van je bedrijf (instelbaar bij Instellingen > Bedrijfsgegevens > Email).
  - [esign_link|klik hier]: de hyperlink naar de factuur of credit nota in buildmate eSign. Na het verticaal streepje, kan je de tekst van de hyperlink wijzigen.`,
    default: `<p>Beste [clientname], </p><p>Bedankt voor het vertrouwen in ons!</p>
  <p><br></p>
  <p>Je vindt jouw [type] [nr] van [document_date] met betrekking tot [title] door te klikken op de link hieronder.</p>
  <p>Het totaal bedrag is [total]€ en vervalt op [expiry_date].</p>
  <p><br></p>
  <p>[esign_link|Klik hier om je [type] te bekijken].</p>
  <p><br></p>
  <p>Indien je vragen of bezorgdheden hebt over deze [type], aarzel niet om ons te contacteren via [email]</p>
  <p><br></p>
  <p>Vriendelijke groet,</p>
  <p>[username]<br>[companyname]</p>`,
    input: SettingsInputType.editor,
    language: SupportedLanguages.nl
  },
  {
    category: SettingCategories.invoicemail,
    name: SettingNames.invoice_mail_title,
    default: `[type]: [title] - [nr]`,
    hint: `Vous pouvez utiliser les éléments suivants dans votre texte, qui seront ensuite remplacés par nos soins lors de l'envoi du courrier:
  - [title]: le titre de votre facture ou note de crédit.
  - [nr]: Le numéro du document, par exemple: fact/0034, où "fact" est le préfixe (réglable dans paramètres->factures->textes sur les devis->préfixe)
   mais aussi tous les autres éléments énumérés ci-dessous.`,
    input: SettingsInputType.text,
    language: SupportedLanguages.fr
  },
  {
    category: SettingCategories.invoicemail,
    name: SettingNames.invoice_mail_pdf_body,
    hint: `Vous pouvez utiliser les éléments suivants dans votre texte, que nous remplacerons lors de l'envoi du courrier :
  - [nom du client] : Le nom du client.
  - [type] : Pour les factures/notes de crédit.
  - [nr] : le numéro du document, par exemple : fact/0034, où "fact" est le préfixe (réglable dans paramètres->factures->textes sur les factures->préfixe).
  - [date_sent] : la date à laquelle vous envoyez le document.
  - [document_date] : la date du document.
  - [title] : le titre de votre facture ou note de crédit.
  - [total] : le total de votre facture ou note de crédit.
  - [expiry_date] : la date d'expiration du document envoyé.
  - [username] : votre nom.
  - [companyname] : le nom de votre entreprise (réglable dans settings->company->data).
  - [email] : l'adresse email de votre société (réglable dans les paramètres->compagnie->données).`,
    default: `Cher [clientname], </p><p> Merci de nous faire confiance !</p>
  <p><br></p>
  <p>Vous trouverez ci-joint la [type] [nr] du [document_date] relative à [titre]</p>
  <p>Le montant total est de [total]€ et expire le [expiry_date].</p>
  <p><br></p>
  <p>Si vous avez des questions ou des préoccupations concernant cette facture, n'hésitez pas à nous contacter à l'adresse suivante [email]</p>
  <p><br></p>
  <p>Cordialement</p>
  <p>[username]<br>[companyname]</p>`,
    input: SettingsInputType.editor,
    language: SupportedLanguages.fr
  },
  {
    category: SettingCategories.invoicemail,
    name: SettingNames.invoice_mail_hub_body,
    hint: `Vous pouvez utiliser les éléments suivants dans votre texte, que nous remplacerons lors de l'envoi du courrier :
  - [nom du client] : Le nom du client.
  - [type] : Pour les factures/notes de crédit.
  - [nr] : le numéro du document, par exemple : fact/0034, où "fact" est le préfixe (réglable dans paramètres->factures->textes sur les factures->préfixe).
  - [date_sent] : la date à laquelle vous envoyez le document.
  - [document_date] : la date du document.
  - [title] : le titre de votre facture ou note de crédit.
  - [total] : le total de votre facture ou note de crédit.
  - [expiry_date] : la date d'expiration du document envoyé.
  - [username] : votre nom.
  - [companyname] : le nom de votre entreprise (réglable dans settings->company->data).
  - [email] : l'adresse email de votre société (réglable dans les paramètres->compagnie->données).
  - [esign_link|cliquez ici] : le lien hypertexte vers la facture ou note de crédit dans buildmate eSign. Après la barre verticale, vous pouvez modifier le texte du lien hypertexte.`,
    default: `Cher [clientname], </p><p> Merci de nous faire confiance !</p>
  <p><br></p>
  <p>Vous pouvez trouver votre [type] [nr] daté du [document_date] concernant [title] en cliquant sur le lien ci-dessous.</p>
  <p>Le montant total est de [total]€ et expire le [expiry_date].</p>
  <p><br></p>
  <p>[esign_link|Cliquez ici pour consulter votre [type]].</p>
  <p><br></p>
  <p>Si vous avez des questions ou des préoccupations concernant cette facture, n'hésitez pas à nous contacter à l'adresse suivante [email]</p>
  <p><br></p>
  <p>Cordialement</p>
  <p>[username]<br>[companyname]</p>`,
    input: SettingsInputType.editor,
    language: SupportedLanguages.fr
  },
  {
    category: SettingCategories.invoicemail,
    name: SettingNames.invoice_mail_title,
    default: `[type]: [title] - [nr]`,
    hint: `You can use the following elements in your title, which will then be replaced by us when sending the mail:
  - [type]: For invoices/credit note.
  - [title]: the title of your invoice or credit note.
  - [nr]: the document number, e.g.: fact/0034, where "fact" is prefix (configurable in settings->invoices->texts on invoices->prefix)
  but also all other elements listed below.`,
    input: SettingsInputType.text,
    language: SupportedLanguages.en
  },
  {
    category: SettingCategories.invoicemail,
    name: SettingNames.invoice_mail_pdf_body,
    hint: `You can use the following elements in your title, which will then be replaced by us when sending the mail:
  - [clientname]: Customer's name.
  - [type]: For invoices/credit notes.
  - [nr]: the document number, e.g.: fact/0034, where "fact" is prefix (configurable in settings->invoices->texts on invoices->prefix)
  - [date_sent]: The date you send the document.
  - [document_date]: The date of the document.
  - [title]: de titel van je factuur of credit nota.
  - [total]: the total of your invoice or credit note.
  - [expiry_date]: The expiration date of the document sent.
  - [username]: your name.
  - [companyname]: the name of your company (adjustable in settings->company->data).
  - [email]: the email address of your company (adjustable in settings->company->data).`,
    default: `<p>Dear [clientname], </p><p>Thanks for your trust in us!</p>
  <p><br></p>
  <p>Enclosed in this email you will find the [type] [nr] of [document_date] relating to [title].</p>
  <p>The total amount is [total]€ and expires on [expiry_date]</p>
  <p><br></p>
  <p>If you have any questions or concerns about this invoice, please do not hesitate to contact us at [email]</p>
  <p><br></p>
  <p>Kind regards,</p>
  <p>[username]<br>[companyname]</p>`,
    input: SettingsInputType.editor,
    language: SupportedLanguages.en
  },
  {
    category: SettingCategories.invoicemail,
    name: SettingNames.invoice_mail_hub_body,
    hint: `You can use the following elements in your title, which will then be replaced by us when sending the mail:
  - [clientname]: Customer's name.
  - [type]: For invoices/credit notes.
  - [nr]: the document number, e.g.: fact/0034, where "fact" is prefix (configurable in settings->invoices->texts on invoices->prefix)
  - [date_sent]: The date you send the document.
  - [document_date]: The date of the document.
  - [title]: de titel van je factuur of credit nota.
  - [total]: the total of your invoice or credit note.
  - [expiry_date]: The expiration date of the document sent.
  - [username]: your name.
  - [companyname]: the name of your company (adjustable in settings->company->data).
  - [email]: the email address of your company (adjustable in settings->company->data).
  - [esign_link|click here]: the hyperlink to the invoice or credit note in buildmate eSign. After the vertical bar, you can set the text of the hyperlink.`,
    default: `<p>Dear [clientname], </p><p>Thanks for your trust in us!</p>
  <p><br></p>
  <p>You can find your [type] [nr] dated [document_date] related to [title] by clicking on the link below.</p>
  <p>The total amount is [total]€ and expires on [expiry_date]</p>
  <p><br></p>
  <p>[esign_link|Click here to view your [type]].</p>
  <p><br></p>
  <p>If you have any questions or concerns about this invoice, please do not hesitate to contact us at [email]</p>
  <p><br></p>
  <p>Kind regards,</p>
  <p>[username]<br>[companyname]</p>`,
    input: SettingsInputType.editor,
    language: SupportedLanguages.en
  }
];


const QuoteMailSettings: ISettingDefault[] = [{
  category: SettingCategories.quotemail,
  name: SettingNames.quote_mail_title,
  default: `Offerte: [title] - [nr]`,
  hint: `U kan volgende elementen gebruiken in uw titel, die dan bij het versturen van de mail door ons vervangen worden:
  - [title]: de titel van je offerte.
  - [nr]: het document nummer, bvb: offer/0034, waar “offer/” voorvoegsel is (instelbaar in Instellingen > Offertes > Algemeen > Offerte voorvoegsel)`,
  input: SettingsInputType.text,
  language: SupportedLanguages.nl
},
  {
    category: SettingCategories.quotemail,
    name: SettingNames.quote_mail_pdf_body,
    hint: `U kan volgende elementen gebruiken in uw tekst, die dan bij het versturen van de mail door ons vervangen worden:
  - [clientname]: De naam van de klant.
  - [nr]: het document nummer, bvb: offer/0034, waar "offer/" voorvoegsel is (instelbaar in Instellingen > Offertes > Algemeen > Offerte voorvoegsel)
  - [date_sent]: de datum waarop jij de offerte verstuurt.
  - [title]: de titel van je offerte.
  - [total]: het totaal van je offerte.
  - [expiry_date]: de vervaldatum van het verstuurde document.
  - [username]: jouw naam.
  - [companyname]: de naam van je bedrijf (instelbaar bij Instellingen > Bedrijfsgegevens > Bedrijfsnaam).
  - [email]: het email adres van je bedrijf (instelbaar bij Instellingen > Bedrijfsgegevens > Email).`,
    default: `<p>Beste [clientname], </p><p>Bedankt voor het vertrouwen in ons!</p>
  <p><br></p>
  <p>Bijgevoegd in deze e-mail vindt u de offerte [nr] van [date_sent] met betrekking tot [title].</p>
  <p><br></p>
  <p>Indien u vragen of bezorgdheden hebt over deze offerte, aarzel niet om ons te contacteren via [email]</p>
  <p><br></p>
  <p>Vriendelijke groet,</p>
  <p>[username]<br>[companyname]</p>`,
    input: SettingsInputType.editor,
    language: SupportedLanguages.nl
  },
  {
    category: SettingCategories.quotemail,
    name: SettingNames.quote_mail_hub_body,
    hint: `U kan volgende elementen gebruiken in uw tekst, die dan bij het versturen van de mail door ons vervangen worden:
  - [clientname]: De naam van de klant.
  - [nr]: het document nummer, bvb: offer/0034, waar "offer/" voorvoegsel is (instelbaar in Instellingen > Offertes > Algemeen > Offerte voorvoegsel)
  - [date_sent]: de datum waarop jij de offerte verstuurt.
  - [title]: de titel van je offerte.
  - [total]: het totaal van je offerte.
  - [expiry_date]: de vervaldatum van het verstuurde document.
  - [username]: jouw naam.
  - [companyname]: de naam van je bedrijf (instelbaar bij Instellingen > Bedrijfsgegevens > Bedrijfsnaam).
  - [email]: het e-mailadres van je bedrijf (instelbaar bij Instellingen > Bedrijfsgegevens > Email).
  - [esign_link|klik hier]: de hyperlink naar de offerte in buildmate eSign. Na het verticaal streepje, kan je de tekst van de hyperlink wijzigen.`,
    default: `<p>Beste [clientname], </p><p>Bedankt voor het vertrouwen in ons!</p>
  <p><br></p>
  <p>Je vindt jouw offerte [nr] van [date_sent] met betrekking tot [title] door te klikken op de link hieronder.</p>
  <p><br></p>
  <p>[esign_link|Klik hier om je offerte digitaal te ondertekenen].</p>
  <p><br></p>
  <p>Indien u vragen of bezorgdheden hebt over deze offerte, aarzel niet om ons te contacteren via [email]</p>
  <p><br></p>
  <p>Vriendelijke groet,</p>
  <p>[username]<br>[companyname]</p>`,
    input: SettingsInputType.editor,
    language: SupportedLanguages.nl
  },
  {
    category: SettingCategories.quotemail,
    name: SettingNames.quote_mail_title,
    default: `Offer: [title] - [nr]`,
    hint: `Vous pouvez utiliser les éléments suivants dans votre texte, qui seront ensuite remplacés par nos soins lors de l'envoi du courrier:
  - [title]: le titre de votre devis.
  - [nr]: Le numéro du document, par exemple: off/0034, où “off” est le préfixe (réglable dans paramètres->factures->textes sur les devis->préfixe)
   mais aussi tous les autres éléments énumérés ci-dessous.`,
    input: SettingsInputType.editor,
    language: SupportedLanguages.fr
  },
  {
    category: SettingCategories.quotemail,
    name: SettingNames.quote_mail_pdf_body,
    hint: `Vous pouvez utiliser les éléments suivants dans votre texte, qui seront ensuite remplacés par nos soins lors de l'envoi du courrier :
  - [clientname]: Le nom du client.
  - [nr]: Le numéro du document, par exemple : devis/0034, où “devis” est le préfixe (réglable dans paramètres->devis->textes sur les devis->préfixe).
  - [date_sent]: la date à laquelle vous envoyez le devis.
  - [title]: le titre de votre devis.
  - [total]: le total de votre devis.
  - [expiry_date]: la date d'expiration du document envoyé.
  - [username]: votre nom.
  - [companyname]: le nom de votre société (configurable dans paramètres->compagnie->données).
  - [email]: l'adresse email de votre société (réglable dans paramètres->compagnie->données).`,
    default: `<p>Cher [clientname], </p><p>Merci de nous avoir fait confiance!</p>
  <p><br></p>
  <p>Vous trouverez en pièce jointe de cet email le devis [nr] de [date_envoi] concernant [title].</p>
  <p><br></p>
  <p>Si vous avez des questions ou des préoccupations concernant ce devis, n'hésitez pas à nous contacter à [email]</p>
  <p><br></p>
  <p>Cordialement,</p>
  <p>[username]<br>[companyname]</p>`,
    input: SettingsInputType.editor,
    language: SupportedLanguages.fr
  },
  {
    category: SettingCategories.quotemail,
    name: SettingNames.quote_mail_hub_body,
    hint: `Vous pouvez utiliser les éléments suivants dans votre texte, qui seront ensuite remplacés par nos soins lors de l'envoi du courrier :
  - [clientname]: Le nom du client.
  - [nr]: Le numéro du document, par exemple : devis/0034, où “devis” est le préfixe (réglable dans paramètres->devis->textes sur les devis->préfixe).
  - [date_sent]: la date à laquelle vous envoyez le devis.
  - [title]: le titre de votre devis.
  - [total]: le total de votre devis.
  - [expiry_date]: la date d'expiration du document envoyé.
  - [username]: votre nom.
  - [companyname]: le nom de votre société (configurable dans paramètres->compagnie->données).
  - [email]: l'adresse email de votre société (réglable dans paramètres->compagnie->données).
  - [esign_link|cliquez ici]: le lien hypertexte vers le devis dans buildmate eSign. Après barre verticale, vous pouvez modifier le texte du lien hypertexte.`,
    default: `<p>Cher [clientname], </p><p>Merci de nous avoir fait confiance!</p>
  <p><br></p>
  <p>Vous pouvez trouver votre devis [nr] daté du [date_sent] concernant [title] en cliquant sur le lien ci-dessous.</p>
  <p><br></p>
  <p>[esign_link|Cliquez ici pour signer votre devis numériquement].</p>
  <p><br></p>
  <p>Si vous avez des questions ou des préoccupations concernant ce devis, n'hésitez pas à nous contacter à [email]</p>
  <p><br></p>
  <p>Cordialement,</p>
  <p>[username]<br>[companyname]</p>`,
    input: SettingsInputType.editor,
    language: SupportedLanguages.fr
  },

  {
    category: SettingCategories.quotemail,
    name: SettingNames.quote_mail_title,
    default: `Offer: [title] - [nr]`,
    hint: `You can use the following elements in your title, which will then be replaced by us when sending the mail:
  - [title]: the title of your offer.
  - [nr]: the document number, e.g.: offer/0034, where “offer” is prefix (configurable in settings->invoices->texts on invoices->prefix)
  but also all other elements listed below.`,
    input: SettingsInputType.text,
    language: SupportedLanguages.en
  },
  {
    category: SettingCategories.quotemail,
    name: SettingNames.quote_mail_pdf_body,
    hint: `ou can use the following elements in your title, which will then be replaced by us when sending the mail:
  - [clientname]: Customer's name.
  - [nr]: the document number, e.g.: quote/0034, where “quote” is prefix (configurable in settings->offers->texts on quotes->prefix)
  - [date_sent]: The date you send the quote.
  - [title]: The titel of the quote.
  - [total]: The total of the quote.
  - [expiry_date]: the expiration date of the document sent.
  - [username]: your name
  - [companyname]: the name of your company (adjustable in settings->company->data).
  - [email]: your company's email address (configurable in settings->company->data).`,
    default: `<p>Dear [clientname], </p><p>Thanks for your trust in us!</p>
  <p><br></p>
  <p>Attached in this email you will find the quote [nr] from [date_sent] regarding [title].</p>
  <p><br></p>
  <p>If you have any questions or concerns about this quote, please do not hesitate to contact us at [email]</p>
  <p><br></p>
  <p>Kind regards,</p>
  <p>[username]<br>[companyname]</p>`,
    input: SettingsInputType.editor,
    language: SupportedLanguages.en
  },
  {
    category: SettingCategories.quotemail,
    name: SettingNames.quote_mail_hub_body,
    hint: `ou can use the following elements in your title, which will then be replaced by us when sending the mail:
  - [clientname]: Customer's name.
  - [nr]: the document number, e.g.: quote/0034, where “quote” is prefix (configurable in settings->offers->texts on quotes->prefix)
  - [date_sent]: The date you send the quote.
  - [title]: The titel of the quote.
  - [total]: The total of the quote.
  - [expiry_date]: the expiration date of the document sent.
  - [username]: your name
  - [companyname]: the name of your company (adjustable in settings->company->data).
  - [email]: your company's email address (configurable in settings->company->data).
  - [esign_link|click here]: the hyperlink to the quote in buildmate eSign. After the vertical bar, you can change the text of the hyperlink.`,
    default: `<p>Dear [clientname], </p><p>Thanks for your trust in us!</p>
  <p><br></p>
  <p>You can find your quote [nr] dated [date_sent] related to [title] by clicking on the link below.</p>
  <p><br></p>
  <p>[esign_link|Click here to sign your quote digitally].</p>
  <p><br></p>
  <p>If you have any questions or concerns about this quote, please do not hesitate to contact us at [email]</p>
  <p><br></p>
  <p>Kind regards,</p>
  <p>[username]<br>[companyname]</p>`,
    input: SettingsInputType.editor,
    language: SupportedLanguages.en
  }
];


const CreditnoteSettingsConfig: ISettingDefault[] = [{
  category: SettingCategories.creditnote,
  name: SettingNames.creditnote_footer_note,
  hint: ``,
  tooltip: `Je kan deze nota nog steeds uitschakelen op creditnota-niveau.`,
  default: ``,
  input: SettingsInputType.editor,
  language: SupportedLanguages.nl
}, {
  category: SettingCategories.creditnote,
  name: SettingNames.creditnote_footer_note,
  hint: ``,
  tooltip: `Vous pouvez toujours désactiver cette note au niveau de la note de crédit`,
  default: ``,
  input: SettingsInputType.editor,
  language: SupportedLanguages.fr
}, {
  category: SettingCategories.creditnote,
  name: SettingNames.creditnote_footer_note,
  hint: ``,
  tooltip: `You can still disable this note at credit note level.`,
  default: ``,
  input: SettingsInputType.editor,
  language: SupportedLanguages.en
}, {
  category: SettingCategories.creditnote,
  name: SettingNames.creditnote_message,
  default: `BTW terug te storten aan de Staat in de mate waarin ze oorspronkelijk in aftrek werd gebracht.`,
  input: SettingsInputType.readonly,
  language: SupportedLanguages.nl
}, {
  category: SettingCategories.creditnote,
  name: SettingNames.creditnote_message,
  default: `TVA à reverser à l'Etat dans la mesure où elle a été initialement déduite.`,
  input: SettingsInputType.readonly,
  language: SupportedLanguages.fr
}, {
  category: SettingCategories.creditnote,
  name: SettingNames.creditnote_message,
  default: `Refund VAT to the State to the extent it was originally deducted.`,
  input: SettingsInputType.readonly,
  language: SupportedLanguages.en
}];


const CreditNoteMailSettings: ISettingDefault[] = [{
  category: SettingCategories.creditnotemail,
  name: SettingNames.creditnote_mail_title,
  default: `[type]: [title] - [nr]`,
  hint: `U kan volgende elementen gebruiken in uw titel, die dan bij het versturen van de mail door ons vervangen worden:
  - [type]: Bij facturen/creditnota.
  - [title]: de titel van je offerte, factuur of credit nota.
  - [nr]: het document nummer, bvb: fact/0034, waar "fact/" voorvoegsel is (instelbaar in Instellingen > Facturen > Algemeen - facturen > Factuur voorvoegsel)
  maar ook alle andere elementen hieronder vermeld.`,
  input: SettingsInputType.text,
  language: SupportedLanguages.nl
},
  {
    category: SettingCategories.creditnotemail,
    name: SettingNames.creditnote_mail_pdf_body,
    hint: `U kan volgende elementen gebruiken in uw tekst, die dan bij het versturen van de mail door ons vervangen worden:
  - [clientname]: De naam van de klant.
  - [type]: Bij facturen/creditnota.
  - [nr]: het document nummer, bvb: fact/0034, waar "fact/" voorvoegsel is (instelbaar in Instellingen > Facturen > Algemeen - facturen > Factuur voorvoegsel)
  - [invoice_nr]: het nummer van de factuur die wordt gecrediteerd.
  - [date_sent]: de datum waarop jij het document verstuurt.
  - [document_date]: de datum van het document.
  - [title]: de titel van je factuur of credit nota.
  - [total]: het totaal van je factuur of credit nota.
  - [expiry_date]: de vervaldatum van het verstuurde document.
  - [username]: jouw naam.
  - [companyname]: de naam van je bedrijf (instelbaar bij Instellingen > Bedrijfsgegevens > Bedrijfsnaam).
  - [email]: het e-mailadres van je bedrijf (instelbaar bij Instellingen > Bedrijfsgegevens > Email).`,
    default: `<p>Beste [clientname], </p>
  <p>Graag willen wij je informeren dat er een creditnota is opgesteld voor factuurnummer [invoice_nr].
  Hieronder vind je de details van deze creditnota:</p>
  <p><br></p>
  <p>Bedrag: [total]€</p>
  <p>Nummer creditnota: [nr]</p>
  <p><br></p>
  <p>De creditnota is bijgevoegd bij deze e-mail en zal worden verwerkt in ons systeem.
  Het gecrediteerde bedrag zal binnen de gebruikelijke termijn op je rekening worden bijgeschreven.</p>
  <p><br></p>
  <p>Mocht je vragen hebben of aanvullende informatie nodig hebben, aarzel dan niet om contact met ons op te nemen.</p>
  <p><br></p>
  <p>Bedankt voor uw begrip en samenwerking.</p>
  <p><br></p>
  <p>Vriendelijke groet,</p>
  <p>[username]<br>[companyname]</p>`,
    input: SettingsInputType.editor,
    language: SupportedLanguages.nl
  },
  {
    category: SettingCategories.creditnotemail,
    name: SettingNames.creditnote_mail_hub_body,
    hint: `U kan volgende elementen gebruiken in uw tekst, die dan bij het versturen van de mail door ons vervangen worden:
  - [clientname]: De naam van de klant.
  - [type]: Bij facturen/creditnota.
  - [nr]: het document nummer, bvb: fact/0034, waar "fact/" voorvoegsel is (instelbaar in Instellingen > Facturen > Algemeen - facturen > Factuur voorvoegsel)
  - [invoice_nr]: het nummer van de factuur die wordt gecrediteerd.
  - [date_sent]: de datum waarop jij het document verstuurt.
  - [document_date]: de datum van het document.
  - [title]: de titel van je factuur of credit nota.
  - [total]: het totaal van je factuur of credit nota.
  - [expiry_date]: de vervaldatum van het verstuurde document.
  - [username]: jouw naam.
  - [companyname]: de naam van je bedrijf (instelbaar bij Instellingen > Bedrijfsgegevens > Bedrijfsnaam).
  - [email]: het e-mailadres van je bedrijf (instelbaar bij Instellingen > Bedrijfsgegevens > Email).
  - [esign_link|klik hier]: de hyperlink naar de factuur of credit nota in buildmate eSign. Na het verticaal streepje, kan je de tekst van de hyperlink wijzigen.`,
    default: `<p>Beste [clientname], </p>
  <p>Graag willen wij je informeren dat er een creditnota is opgesteld voor factuurnummer [invoice_nr].
  Hieronder vind je de details van deze creditnota:</p>
  <p><br></p>
  <p>Bedrag: [total]€</p>
  <p>Nummer creditnota: [nr]</p>
  <p><br></p>
  <p>De creditnota [esign_link|kan je hier bekijken]. Het gecrediteerde bedrag zal binnen de gebruikelijke termijn op je rekening worden bijgeschreven.</p>
  <p><br></p>
  <p>Mocht je vragen hebben of aanvullende informatie nodig hebben, aarzel dan niet om contact met ons op te nemen.</p>
  <p><br></p>
  <p>Bedankt voor uw begrip en samenwerking.</p>
  <p><br></p>
  <p>Vriendelijke groet,</p>
  <p>[username]<br>[companyname]</p>`,
    input: SettingsInputType.editor,
    language: SupportedLanguages.nl
  },
  {
    category: SettingCategories.creditnotemail,
    name: SettingNames.creditnote_mail_title,
    default: `[type]: [title] - [nr]`,
    hint: `Vous pouvez utiliser les éléments suivants dans votre texte, qui seront ensuite remplacés par nos soins lors de l'envoi du courrier:
  - [title]: le titre de votre facture ou note de crédit.
  - [nr]: Le numéro du document, par exemple: fact/0034, où "fact" est le préfixe (réglable dans paramètres->factures->textes sur les devis->préfixe)
   mais aussi tous les autres éléments énumérés ci-dessous.`,
    input: SettingsInputType.text,
    language: SupportedLanguages.fr
  },
  {
    category: SettingCategories.creditnotemail,
    name: SettingNames.creditnote_mail_pdf_body,
    hint: `Vous pouvez utiliser les éléments suivants dans votre texte, que nous remplacerons lors de l'envoi du courrier :
  - [nom du client] : Le nom du client.
  - [type] : Pour les factures/notes de crédit.
  - [nr] : le numéro du document, par exemple : fact/0034, où "fact" est le préfixe (réglable dans paramètres->factures->textes sur les factures->préfixe).
  - [invoice_nr]: le numéro de la facture créditée.
  - [date_sent] : la date à laquelle vous envoyez le document.
  - [document_date] : la date du document.
  - [title] : le titre de votre facture ou note de crédit.
  - [total] : le total de votre facture ou note de crédit.
  - [expiry_date] : la date d'expiration du document envoyé.
  - [username] : votre nom.
  - [companyname] : le nom de votre entreprise (réglable dans settings->company->data).
  - [email] : l'adresse email de votre société (réglable dans les paramètres->compagnie->données).`,
    default: `Cher [clientname], </p>
  <p>Nous souhaitons vous informer qu'une note de crédit a été établie pour le numéro de facture [invoice_nr].
  Vous trouverez ci-dessous le détail de cet avoir :</p>
  <p><br></p>
  <p>Montant : [total]€</p>
  <p>Numéro de note de crédit: [nr]</p>
  <p><br></p>
  <p>La note de crédit est jointe à cet e-mail et sera traitée dans notre système.
  Le montant crédité sera crédité sur votre compte dans le délai habituel.</p>
  <p><br></p>
  <p>Si vous avez des questions ou avez besoin d'informations supplémentaires, n'hésitez pas à nous contacter.</p>
  <p><br></p>
  <p>Merci pour votre compréhension et votre coopération.</p>
  <p><br></p>
  <p>Cordialement</p>
  <p>[username]<br>[companyname]</p>`,
    input: SettingsInputType.editor,
    language: SupportedLanguages.fr
  },
  {
    category: SettingCategories.creditnotemail,
    name: SettingNames.creditnote_mail_hub_body,
    hint: `Vous pouvez utiliser les éléments suivants dans votre texte, que nous remplacerons lors de l'envoi du courrier :
  - [nom du client] : Le nom du client.
  - [type] : Pour les factures/notes de crédit.
  - [nr] : le numéro du document, par exemple : fact/0034, où "fact" est le préfixe (réglable dans paramètres->factures->textes sur les factures->préfixe).
  - [date_sent] : la date à laquelle vous envoyez le document.
  - [document_date] : la date du document.
  - [title] : le titre de votre facture ou note de crédit.
  - [total] : le total de votre facture ou note de crédit.
  - [expiry_date] : la date d'expiration du document envoyé.
  - [username] : votre nom.
  - [companyname] : le nom de votre entreprise (réglable dans settings->company->data).
  - [email] : l'adresse email de votre société (réglable dans les paramètres->compagnie->données).
  - [esign_link|cliquez ici] : le lien hypertexte vers la facture ou note de crédit dans buildmate eSign. Après la barre verticale, vous pouvez modifier le texte du lien hypertexte.`,
    default: `Cher [clientname], </p>
  <p>Nous souhaitons vous informer qu'une note de crédit a été établie pour le numéro de facture [invoice_nr].
  Vous trouverez ci-dessous le détail de cet avoir :</p>
  <p><br></p>
  <p>Montant : [total]€</p>
  <p>Numéro de note de crédit: [nr]</p>
  <p><br></p>
  <p>La note de crédit [esign_link|peut être consultée ici]. Le montant crédité sera crédité sur votre compte dans le délai habituel.</p>
  <p><br></p>
  <p>Si vous avez des questions ou avez besoin d'informations supplémentaires, n'hésitez pas à nous contacter.</p>
  <p><br></p>
  <p>Merci pour votre compréhension et votre coopération.</p>
  <p><br></p>
  <p>Cordialement</p>
  <p>[username]<br>[companyname]</p>`,
    input: SettingsInputType.editor,
    language: SupportedLanguages.fr
  },
  {
    category: SettingCategories.creditnotemail,
    name: SettingNames.creditnote_mail_title,
    default: `[type]: [title] - [nr]`,
    hint: `You can use the following elements in your title, which will then be replaced by us when sending the mail:
  - [type]: For invoices/credit note.
  - [title]: the title of your invoice or credit note.
  - [nr]: the document number, e.g.: fact/0034, where "fact" is prefix (configurable in settings->invoices->texts on invoices->prefix)
  but also all other elements listed below.`,
    input: SettingsInputType.text,
    language: SupportedLanguages.en
  },
  {
    category: SettingCategories.creditnotemail,
    name: SettingNames.creditnote_mail_pdf_body,
    hint: `You can use the following elements in your title, which will then be replaced by us when sending the mail:
  - [clientname]: Customer's name.
  - [type]: For invoices/credit notes.
  - [nr]: the document number, e.g.: fact/0034, where "fact" is prefix (configurable in settings->invoices->texts on invoices->prefix)
  - [date_sent]: The date you send the document.
  - [document_date]: The date of the document.
  - [title]: de titel van je factuur of credit nota.
  - [total]: the total of your invoice or credit note.
  - [expiry_date]: The expiration date of the document sent.
  - [username]: your name.
  - [companyname]: the name of your company (adjustable in settings->company->data).
  - [email]: the email address of your company (adjustable in settings->company->data).`,
    default: `<p>Dear [clientname], </p>
  <p>We would like to inform you that a credit note has been created for invoice number [invoice_nr].
  Below you will find the details of this credit note:</p>
  <p><br></p>
  <p>Amount: [total]€</p>
  <p>Credit note number: [nr]</p>
  <p><br></p>
  <p>The credit note is attached to this email and will be processed in our system.
  The credited amount will be credited to your account within the usual period.</p>
  <p><br></p>
  <p>If you have any questions or require additional information, please do not hesitate to contact us.</p>
  <p><br></p>
  <p>Thank you for your understanding and cooperation.</p>
  <p><br></p>
  <p>Kind regards,</p>
  <p>[username]<br>[companyname]</p>`,
    input: SettingsInputType.editor,
    language: SupportedLanguages.en
  },
  {
    category: SettingCategories.creditnotemail,
    name: SettingNames.creditnote_mail_hub_body,
    hint: `You can use the following elements in your title, which will then be replaced by us when sending the mail:
  - [clientname]: Customer's name.
  - [type]: For invoices/credit notes.
  - [nr]: the document number, e.g.: fact/0034, where "fact" is prefix (configurable in settings->invoices->texts on invoices->prefix)
  - [date_sent]: The date you send the document.
  - [document_date]: The date of the document.
  - [title]: de titel van je factuur of credit nota.
  - [total]: the total of your invoice or credit note.
  - [expiry_date]: The expiration date of the document sent.
  - [username]: your name.
  - [companyname]: the name of your company (adjustable in settings->company->data).
  - [email]: the email address of your company (adjustable in settings->company->data).
  - [esign_link|click here]: the hyperlink to the invoice or credit note in buildmate eSign. After the vertical bar, you can set the text of the hyperlink.`,
    default: `<p>Dear [clientname], </p>
  <p>We would like to inform you that a credit note has been issued for invoice number [invoice_nr].
  Below you will find the details of this credit note:</p>
  <p><br></p>
  <p>Amount: [total]€</p>
  <p>Credit note number: [nr]</p>
  <p><br></p>
  <p>You can view the credit note [esign_link|here]. The credited amount will be credited to your account within the usual period.</p>
  <p><br></p>
  <p>If you have any questions or require additional information, please do not hesitate to contact us.</p>
  <p><br></p>
  <p>Thank you for your understanding and cooperation.</p>
  <p><br></p>
  <p>Kind regards,</p>
  <p>[username]<br>[companyname]</p>`,
    input: SettingsInputType.editor,
    language: SupportedLanguages.en
  }
];

const GeneralSettingsConfig: ISettingDefault[] = [
  {
    category: SettingCategories.general,
    name: SettingNames.general_conditions,
    default: '',
    input: SettingsInputType.editor,
    language: '*'
  },
  // {
  //   category: SettingCategories.general,
  //   name: SettingNames.vat_0perc,
  //   default: 'shifted',
  //   input: SettingsInputType.checkbox,
  //   language: '*'
  // }
];

export const SettingsConfig: ISettingDefault[] = [
  ...GeneralSettingsConfig,
  ...QuoteSettingsConfig,
  ...QuoteMailSettings,
  ...InvoiceSettingsConfig,
  ...InvoiceMailSettings,
  ...CreditnoteSettingsConfig,
  ...CreditNoteMailSettings
];
