import {Inject, inject, Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {DOCUMENT} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private renderFactory = inject(RendererFactory2);

  public renderer: Renderer2;

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.renderer = this.renderFactory.createRenderer(null, null);
  }

  public enableStagingMode() {
    this.renderer.addClass(this.document.body, 'staging-theme');
  }
}
