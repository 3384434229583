
const kCharToHtmlEntity: Record<string, string> = {
  "&": "&amp;",
  "<": "&lt;",
  ">": "&gt;",
  '"': "&quot;",
  "'": "&#39;"
};
const kHtmlEntityToChar = Object.entries(kCharToHtmlEntity).reduce((acc, [k, v]) => {
  acc[v] = k;
  return acc;
}, {"&#34;": '"'} as Record<string, string>);

const kAllBlankLinesRegex = /^\s*$(?:\r\n?|\n)/gm;
const kAllEscapedHtmlEntitiesRegex = /&(?:amp|lt|gt|quot|#34|#39);/gi;
const kAllHtmlBreakTagsRegex = /<\/?br>/g;
const kAllHtmlHyperlinksRegex = /<a href="([^"]*?)">[^<]*?<\/a>/g;
const kAllHtmlTags = /<[^>]*?>/g;
const kAllReservedRegexCharsRegex = /[.*+?^${}()|[\]\\]/g;
const kAllUnescapedHtmlCharsRegex = /[&<>"']/g;
const kFirstWordPrefixRegex = /\w{1,2}/;
const kHasDigitOrUnderscoreRegex = /[\d_]/;
const kHtmlHeadingRegex = /<h1>(?:.|\n)*<\/h1>/;
const kHtmlHeadRegex = /<head>(?:.|\n)*<\/head>/;
const kIsAsciiLowerKebabCaseRegex = /^[a-z][a-z0-9-]*[a-z0-9]$/;
const kIsStartOfSentenceRegex = /(?:[.?!]|\n|\r)[ \t]*$/;
const kIsStartOfSentenceHtmlRegex = /(?:[.?!]|<p>|<\\?br>)\s*$/;
const kIsWhiteSpaceRegex = /\s/;
const kIsThousandsSeparatorRegex = /[\s,._]/;

export function strip(a: string): string {
  return a.replace(/\s|\-|\_/g, "").toLowerCase();
}
export function lookalike(a: string, b: string) {
  const ax = a.replace(/\s|\-|\_/g, "").toLowerCase();
  const bx = b.replace(/\s|\-|\_/g, "").toLowerCase();
  return ax === bx;
}

export function capitalize(text: string): string {
  const prefixMatch = kFirstWordPrefixRegex.exec(text);
  if (!prefixMatch) return text; // not a word
  const prefix = prefixMatch[0];
  if (prefix.length > 1 && prefix === prefix.toUpperCase()) {
    return text; // acronym
  } else if (kHasDigitOrUnderscoreRegex.test(text)) {
    return text; // code of some sort
  } else {
    return (
      text.slice(0, prefixMatch.index) +
      text.charAt(prefixMatch.index).toUpperCase() +
      text.slice(prefixMatch.index + 1)
    );
  }
}

export function anyChar(text: string, predicate: (char: string) => boolean, index: number = 0, endIndex: number = text.length): boolean {
  if (index < 0) {
    index = text.length + index;
    if (index < 0) index = 0;
  }
  if (endIndex < 0) {
    endIndex = text.length + endIndex;
  } else if (endIndex > text.length) {
    endIndex = text.length;
  }
  for (; index < endIndex; index++) {
    if (predicate(text[index])) return true;
  }
  return false;
}

export function everyChar(text: string, predicate: (char: string) => boolean, index: number = 0, endIndex: number = text.length): boolean {
  if (index < 0) {
    index = text.length + index;
    if (index < 0) index = 0;
  }
  if (endIndex < 0) {
    endIndex = text.length + endIndex;
  } else if (endIndex > text.length) {
    endIndex = text.length;
  }
  for (; index < endIndex; index++) {
    if (!predicate(text[index])) return false;
  }
  return true;
}

export function escapeHtml(text: string): string {
  return text.replace(kAllUnescapedHtmlCharsRegex, (m) => kCharToHtmlEntity[m]);
}

export function escapeRegExp(text: string): string {
  return text.replace(kAllReservedRegexCharsRegex, '\\$&');
}

export function isDecimalSeparator(char: string): boolean {
  return char === '.' || char === ',';
}

export function isThousandsSeparator(char: string): boolean {
  return kIsThousandsSeparatorRegex.test(char);
}

export function isDigit(char: string): boolean {
  return char >= '0' && char <= '9';
}

export function isLatinLetter(char: string): boolean {
  return (char >= 'a' && char <= 'z') || (char >= 'A' && char <= 'Z');
}

export function isLowerKebabCaseIdentifier(text: string): boolean {
  return kIsAsciiLowerKebabCaseRegex.test(text);
}

export function isSign(char: string): boolean {
  return char === '+' || char === '-';
}

export function isWhiteSpace(char: string): boolean {
  return kIsWhiteSpaceRegex.test(char);
}

export function lastChar(text: string): string {
  return text.length === 0 ? '' : text[text.length - 1];
}

// Discussion: https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
export function roundNumberToDecimalDigits(value: number | string, decimalDigits: number): string {
  return (+value).toLocaleString('en', {
    maximumFractionDigits: decimalDigits,
    useGrouping: false
  });
}

export function roundNumberToFixedDecimalDigits(value: number | string, decimalDigits: number): string {
  const str = roundNumberToDecimalDigits(value, decimalDigits);
  const [int, frac = ''] = str.split('.');
  return `${int}.${frac.padEnd(decimalDigits, '0')}`;
}

export function shouldCapitalize(text: string, index: number): boolean {
  return index === 0 || kIsStartOfSentenceRegex.test(text.slice(0, index));
}

export function shouldCapitalizeHtml(text: string, index: number): boolean {
  return index === 0 || kIsStartOfSentenceHtmlRegex.test(text.slice(0, index));
}

export function stripBlankLines(text: string): string {
  return text.replace(kAllBlankLinesRegex, '');
}

export function stripHtml(text: string, removeFirstHeading = false): string {
  if (removeFirstHeading) {
    text = text.replace(kHtmlHeadingRegex, '');
  }
  return unescapeHtml(
    text
      // remove head so title etc... is not shown
      .replace(kHtmlHeadRegex, '')
      // replace <br> with newline
      .replace(kAllHtmlBreakTagsRegex, '\n')
      // replace hyperlinks with the URL itself
      .replace(kAllHtmlHyperlinksRegex, (_, url) => url)
      // remove all other tags
      .replace(kAllHtmlTags, '')
      // remove all trailing and leading whitespace
      .trim()
  );
}

export function stripLeadingChars(text: string, char: string): string {
  let index = 0;
  while (text[index] === char) index++;
  return text.slice(index);
}

export function uncapitalize(text: string): string {
  const prefixMatch = kFirstWordPrefixRegex.exec(text);
  if (!prefixMatch) return text; // not a word
  const prefix = prefixMatch[0];
  if (prefix.length > 1 && prefix === prefix.toUpperCase()) {
    return text; // acronym
  } else if (kHasDigitOrUnderscoreRegex.test(text)) {
    return text; // code of some sort
  } else {
    return (
      text.slice(0, prefixMatch.index) +
      text.charAt(prefixMatch.index).toLowerCase() +
      text.slice(prefixMatch.index + 1)
    );
  }
}

export function unescapeHtml(string: string): string {
  return string.replace(kAllEscapedHtmlEntitiesRegex, (m) => {
    return kHtmlEntityToChar[m.toLowerCase()];
  });
}

export function nextNr(input: string | number): string {
  if (!input) return "001";
  if (typeof input !== "string") input = "" + input;

  // Use regular expression to find the numeric part of the string
  const match = input.match(/(\d+)/);

  if (match) {
      const numberPart = match[0]; // Extract the numeric part
      const incrementedNumber = (parseInt(numberPart, 10) + 1).toString(); // Increment the numeric part

      // Calculate the number of zeros needed to maintain the same length as the original numeric part
      const paddedNumber = incrementedNumber.padStart(numberPart.length, '0');

      // Reconstruct the new string
      return input.replace(numberPart, paddedNumber);

  } else {

    // If there's no numeric part, return the input with 001 attached
    return input + "001";
  }
}
// Test cases //
// console.log(nextNr(null));   // Output: 001
// console.log(nextNr(""));     // Output: 001
// console.log(nextNr("R001"));   // Output: R002
// console.log(nextNr("R099"));   // Output: R100
// console.log(nextNr("R999"));   // Output: R1000
// console.log(nextNr("KK-102")); // Output: KK-103
// console.log(nextNr("12xx"));   // Output: 12xx
// console.log(nextNr("A12B"));   // Output: A13B
// console.log(nextNr("99Balloons")); // Output: 100Balloons
