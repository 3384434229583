import {CancelReasons, Id, ServerResponse, ServerStatus, YN} from '../types';
import {UserRole, UserStatus} from './user';
import {IWorkday} from "./planning";
import {ICompany} from './company';
import {IAddress} from './address';


///////////////
// Profile  //
/////////////
export enum SupportedLanguages {
  'nl' = 'nl-BE',
  'fr' = 'fr-BE',
  'en' = 'en-BE'
}

export enum SupportedCountryCodes {
  BE = 'BE',
  NL = 'NL',
  FR = 'FR',
  EN = 'EN',
  DE = 'DE',
  LU = 'LU'
}


export type SettingLanguages = '*' | SupportedLanguages;

export interface ILanguage {
  value: SupportedLanguages;
  label: string;
  short?: string;
}

export function getSupportedLanguage(
  inputLanguage: any, defaultLanguage = SupportedLanguages.en
): SupportedLanguages {
  if (!inputLanguage || typeof inputLanguage !== "string") {
    return defaultLanguage;
  }

  // convert to nl-BE, fr-BE, en-BE, ... format
  const language =
    inputLanguage.slice(0, 2).toLowerCase() +
    inputLanguage.slice(2).toUpperCase();

  for (const lang of Object.entries(SupportedLanguages)) {
    if (lang[1] === language) return language;
    else if (lang[0] === language) return lang[1];
  }
  return defaultLanguage;
}

export function matchSupportedLanguage(short: string): SupportedLanguages {
  for (const lang of Object.entries(SupportedLanguages)) {
    if (lang[1].slice(0, 2) === short) return lang[1];
  }
  return SupportedLanguages.nl;
}


///////////////////
// Text settings //
///////////////////

export enum SettingNames {
  general_conditions = 'general_conditions',

  quote_prefix = 'quote_prefix',
  quote_valid = 'quote_valid',

  quote_with_0perc_vat_reverse_normal = 'quote_with_0perc_vat_reverse_normal',
  quote_with_0perc_vat_reverse_shifted = 'quote_with_0perc_vat_reverse_shifted',
  quote_with_0perc_vat_reverse_intra = 'quote_with_0perc_vat_reverse_intra',
  quote_with_0perc_vat_exempt = 'quote_with_0perc_vat_exempt',
  quote_with_6perc_vat = 'quote_with_6perc_vat',
  quote_with_21perc_vat = 'quote_with_21perc_vat',

  quote_footer_note = 'quote_footer_note',
  quote_mail_title = 'quote_mail_title',
  quote_mail_pdf_body = 'quote_mail_pdf_body',
  quote_mail_hub_body = 'quote_mail_hub_body',

  invoice_prefix = 'invoice_prefix',
  invoice_valid = 'invoice_valid',
  invoice_ogm = 'invoice_ogm',

  invoice_with_0perc_vat_reverse_normal = 'invoice_with_0perc_vat_reverse_normal',
  invoice_with_0perc_vat_reverse_shifted = 'invoice_with_0perc_vat_reverse_shifted',
  invoice_with_0perc_vat_reverse_intra = 'invoice_with_0perc_vat_reverse_intra',
  invoice_with_0perc_vat_exempt = 'invoice_with_0perc_vat_exempt',
  invoice_with_6perc_vat = 'invoice_with_6perc_vat',
  invoice_with_21perc_vat = 'invoice_with_21perc_vat',

  invoice_footer_note = 'invoice_footer_note',

  invoice_mail_title = 'invoice_mail_title',
  invoice_mail_pdf_body = 'invoice_mail_pdf_body',
  invoice_mail_hub_body = 'invoice_mail_hub_body',

  accounting_confirmed = 'accounting_confirmed',
  accounting_package = 'accounting_package',
  accounting_ledger = 'ledgeraccount',
  accounting_companyId = 'accCompanyId',
  accounting_taxed = 'accounting_taxed',
  accounting_method = 'accounting_method',
  accounting_booking_no = 'determineBookingNoByAccounting',

  creditnote_footer_note = 'creditnote_footer_note',
  creditnote_message = 'creditnote_message',

  creditnote_mail_title = 'creditnote_mail_title',
  creditnote_mail_pdf_body = 'creditnote_mail_pdf_body',
  creditnote_mail_hub_body = 'creditnote_mail_hub_body',

  planning_workdays = 'planning_workdays',

  power_clientnr = 'power_clientnr',
  power_username = 'power_username',
  power_password = 'power_password',
  power_company_migration_config = 'power_company_migration_config',
  power_users_migration_config = 'power_users_migration_config',
  power_employees_migration_config = 'power_employees_migration_config',
  power_clients_migration_config = 'power_clients_migration_config',
  power_articles_migration_config = 'power_articles_migration_config',
  power_equipment_migration_config = 'power_equipment_migration_config',
  power_work_hours_migration_config = 'power_work_hours_migration_config',
  power_work_packages_migration_config = 'power_work_packages_migration_config'
}

export enum SettingCategories {
  general = 'general',
  quote = 'quote',
  quotemail = 'quotemail',
  invoice = 'invoice',
  accounting = 'accounting',
  invoicemail = 'invoicemail',
  creditnote = 'creditnote',
  creditnotemail = 'creditnotemail',
  planning = 'planning',
  power = 'power'
}

// Default + definitions
export enum SettingsInputType {
  text = 'text',
  number = 'number',
  area = 'area',
  editor = 'editor',
  readonly = 'readonly',
  list = 'list',
  checkbox = 'checkbox'
}

export interface IMinimalSetting {
  name: string;
  value: string;
}

export interface ISettingSchema extends IMinimalSetting {
  id?: Id;
  category: SettingCategories;
  language: SettingLanguages;
  secure?: YN;
}

export type ISaveSettingSchema = ISettingSchema[];

export interface ISettingDefault {
  category: SettingCategories;
  name: SettingNames;
  default: number | string;
  hint?: string;
  tooltip?: string;
  input: SettingsInputType;
  language: SettingLanguages;
  list?: string;
  secure?: YN;
}

export interface ISetting extends IMinimalSetting {
  id?: Id;
  company?: Id;
  category: SettingCategories;
  input: SettingsInputType;
  list?: string;
  hint?: string;
  tooltip?: string;
  name: string;
  value: string;
  language: SettingLanguages;
  secure?: YN;
}

// Responses
export interface ISettingsResponse extends ServerResponse {
  settings: ISetting[];
}

// Functions
export function defAsSetting(def: ISettingDefault, company?: Id): ISetting {
  return {
    category: def.category,
    name: def.name,
    hint: def.hint || '',
    tooltip: def.tooltip || '',
    value: '' + def.default,
    company,
    input: def.input,
    list: def.list,
    language: def.language,
    secure: def.secure
  };
}

export function setSetting(settings: Array<ISetting>, name: string, value: string, category: SettingCategories, secure = YN.kNo) {
  const line = settings.find(s => (s.name === name) && (s.category === category));
  if (line) {
    line.value = value;
  } else {
    settings.push({name, category, value, language: '*', input: SettingsInputType.list, secure});
  }
}
export function getSetting(settings: Array<ISetting>, name: string, category: SettingCategories, secure = YN.kNo): string {
  const line = settings.find(s => (s.name === name) && (s.category === category));
  if (!line) {
    settings.push({name, category, value: '', language: '*', input: SettingsInputType.list, secure});
    return '';
  } else {
    return line.value;
  }
}
export function removeSetting(settings: Array<ISetting>, name: string, category: SettingCategories) {
  const index = settings.findIndex(s => (s.name === name) && (s.category === category));
  if (index >= 0) settings.splice(index, 1);
}


///////////////
// Suppliers //
///////////////
export interface ISaveSuppliersSchema {
  id: Id;
  selected: YN;
  linked?: YN;
  auth1?: string;
  auth2?: string;
  auth3?: string;
  auth4?: string;
}

export interface IFetchCompanySupplier extends ISaveSuppliersSchema {
  name: string;
  code: string;
  image: string;
}

// Responses
export interface ICompanySuppliersResponse extends ServerResponse {
  suppliers: ISaveSuppliersSchema[];
}

export interface IFetchCompanySuppliersResponse extends ServerResponse {
  suppliers: Array<IFetchCompanySupplier>;
}


////////////////
// Collection //
////////////////
export enum CollectionType {
  // ItemTypes
  article = 'article',        // Own article
  product = 'product',        // Product from supplier
  time = 'time',              // Time cost/price
  equipment = 'equipment',    // Equipment (materieel)
  stock = 'stock',            // Stock
  template = 'template',      // Template
  post = 'post',              // Post/Work package

  // Extra's
  label = 'label',
  unit = 'unit',
  employee_function = 'employee-function',
}

export interface ICollectionItem {
  id?: Id;
  company?: Id;
  type?: CollectionType
  position: number;
  name: string;
}
export type ICollection = Array<ICollectionItem>;

export interface ICollectionResponse extends ServerResponse {
  collection: ICollection;
}


////////////////
// Accounting //
////////////////

export interface IAccountingParamsResponse extends ServerResponse {
  settings: Array<ISetting>;
  options: IAccountingSoftware;
}

////////////
// POWER //
///////////

export interface IPowerParamsResponse extends ServerResponse {
  settings: Array<ISetting>;
  appKey: string;
}

///////////////
// Planning //
/////////////

export interface IWorkdayResponse extends ServerResponse {
  workdays: Array<IWorkday<number>>;
}

///////////////////
// Admin actions //
///////////////////

export interface IUserStat {
  id: Id;
  company: Id;
  customer: string;
  name: string;
  email: string;
  domains: string;
  clients: number;
  status: UserStatus;
  role: UserRole;
  invoices: number;
  quotes: number;
  nrhours: number; // nr of hours since last activity
  lastSeen: string;
  verified: YN;
  created: string;
  news: YN;
}

export interface ICompanyStat {
  id: Id;
  name: string;
  vat_nr: string;
  website: string;
  street: string;
  city: string;
  phone: string;
  email: string;
  customer: string;
  subscription: string;
  phase: string;
  end: string;
  created: string;
  type: string;
  users: IUserStat[];
  activeUsers?: number;
  selected: boolean;
  license: string;
  hasUnconfirmed: boolean;
  interval_type: string;
  maxstorage: number;
  stats: {
    [key: string]: string;
  }
  numbers: {
    [key: string]: number;
  },
  impersonate: YN;
  cancel_reason?: CancelReasons;
  cancel_comment?: string;
  sector: string;
}


export interface ISQLResponse extends ServerResponse {
  duration?: number;
  results: Array<{ [colName: string]: string | number }>;
}

export interface ISQLDumpResponse extends ServerResponse {
  duration?: number;
  sql: string;
  tables: string[];
  warnings?: string[];
}

/////////////
// Reports //
/////////////
export enum ReportType {
  templates = 1,
  quotes = 2,
  tableInfo = 3,
  doubleAddresses = 4,
  customers = 5,
  usage = 6,
  companies = 7
}
export type IReportList = Array<{name: string, id: ReportType}>;

export const ReportList: IReportList = [
  {name: 'buildmate customers', id: ReportType.customers},
  {name: 'Template usage', id: ReportType.templates},
  {name: 'Quotes per company', id: ReportType.quotes},
  {name: 'Table info', id: ReportType.tableInfo},
  {name: 'Double Addresses', id: ReportType.doubleAddresses},
  {name: 'API usage', id: ReportType.usage},
  {name: 'Companies', id: ReportType.companies}
];

export interface IReportListResponse extends ServerResponse {
  reports: IReportList;
}

// used for alignment and formatting
export enum IResultType {
  kString = 10,
  kStringNW = 11,
  kNumber = 20,
  kNumberTotal = 21,
  kDecimal = 30,
  kDecimalTotal = 31,
  kDate = 40
}

export interface IResultBlock {
  titles: Array<string>;
  types?: Array<IResultType>;
  data: Array<IResultBlock | Array<string | number>>;
}
export interface IReportResponse extends ServerResponse {
  duration?: number;
  results: IResultBlock;
}
export interface IReportErrorResponse extends ServerResponse {
  status: ServerStatus.kNOK;
  message: string;
  duration: number;
}

export interface IUserStatResponse extends ServerResponse {
  users: IUserStat[];
}

export interface ICompanyStatResponse extends ServerResponse {
  companies: ICompanyStat[];
}

////////////////
// Accounting //
////////////////
export type IAccountingSoftwareInput = {
  nl: string; nl_hint?: string;
  fr: string; fr_hint?: string;
  en: string; en_hint?: string;
  hide?: boolean;
  mandatory?: boolean;
  default?: string;
  category?: AccountingInputCategory;
  type?: 'isoDate' | 'boolean';
}
export type IAccountingSoftwarePackage = {
  name: string;
  invoiceNumberType?: 'Int32';
  inputs: Record<string, IAccountingSoftwareInput>;
  [AccountingPayload.purchase]: Array<AccountingInput>;
  [AccountingPayload.invoice]: Array<AccountingInput>;
  [AccountingPayload.auth]: Array<AccountingInput>;
  [AccountingPayload.getresetlink]: Array<AccountingInput>;
  in_dev?: boolean;
}
export type IAccountingSoftware = Record<AccountingSoftware, IAccountingSoftwarePackage>;

export enum AccountingInput {
  accSoftware = 'accSoftware',
  sourceSoftware = 'sourceSoftware',
  sourceClientId = 'sourceClientId',
  sourceCompanyId = 'sourceCompanyId',
  accClientId = 'accClientId',
  accCompanyId = 'accCompanyId',
  salesJournal = 'salesJournal',
  salesJournalCreditNotes = 'salesJournalCreditNotes',
  salesInvoices = 'salesInvoices',
  salesDetails = 'salesDetails',
  ledgeraccount = 'ledgeraccount',
  determineBookingNoByAccounting = 'determineBookingNoByAccounting',
  purchaseJournals = 'purchaseJournals',
  modifiedDateFrom = 'modifiedDateFrom',
  permanentApiKey = 'permanentApiKey',
  password = 'password',
}

export enum AccountingInputCategory {
  sales = 'SALES',
  purchases = 'PURCHASES',
  payments = 'PAYMENTS',
  projects= 'PROJECTS'
}

export enum AccountingMethod {
  instant = 'instant',
  manual = 'manual'
}

export enum AccountingSoftware {
  exactonline = 'EXACTONLINE',
  octopus = 'OCTOPUS',
  yuki = 'YUKI'
}

export enum AccountingPayload {
  purchase = 'purchase',
  invoice = 'invoice',
  auth = 'auth',
  getresetlink = 'getresetlink'
}
