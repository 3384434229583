import distro from "./distro-settings.json";

//////////////////////
// BlueStagingStack //
//////////////////////

export const environment = {
  name: "buildmate staging",
  productName: "buildmate",
  productWebsite: "https://buildmate.be",
  applicationWebsite: distro.staging.clientUrl,
  clientHubWebsite: distro.staging.clienthubUrl,
  production: false,
  staging: true,
  logo: "Icon_Buildmate_RGB_staging.svg",
  appId: "be.buildmate.staging",
  userPilot: "STG-NX-16a87389",
  server: distro.staging.apiUrl,
  cognito: {
    authenticationFlow: distro.staging.authenticationFlow,
    endpoint: distro.staging.userPoolUrl,
    region: distro.staging.userPoolRegion,
    userPoolId: distro.staging.userPoolId,
    userPoolClientId: distro.staging.userPoolClientId
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any;
