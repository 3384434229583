import {Component, HostListener, inject, LOCALE_ID, NgZone, OnInit} from '@angular/core';
import {LanguageService} from "./services/language.service";
import {ThemeService} from "./services/theme.service";
import {environment} from "core/environments/environment";
import {App, URLOpenListenerEvent} from '@capacitor/app';
import {Router} from "@angular/router";
import {SplashScreen} from "@capacitor/splash-screen";
import {DeviceService} from "./services/device.service";
import {IonApp, IonRouterOutlet} from "@ionic/angular/standalone";
import {register} from 'swiper/element/bundle';
import localeNl from '@angular/common/locales/nl-BE';
import {registerLocaleData} from '@angular/common';
import {Platform} from "@ionic/angular";
import {_plannerMobileChosenPeriod} from "core/localstorage-keys";

registerLocaleData(localeNl, 'nl-BE');

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
	standalone: true,
	imports: [IonApp, IonRouterOutlet],
  providers: [{provide: LOCALE_ID, useValue: 'nl-BE'}]
})
export class AppComponent implements OnInit {
	private languageService = inject(LanguageService);
	private themeService = inject(ThemeService);
	private deviceService = inject(DeviceService);
	private zone = inject(NgZone);
	private router = inject(Router);
	private platform = inject(Platform);

	@HostListener('document:readystatechange', ['$event'])
	onReadyStateChanged(event: any) {
		if (event.target.readyState === 'complete') {
			SplashScreen.hide();
		}
	}

	constructor() {
		// register Swiper custom elements
		register();

		this.initializeApp();
		this.languageService.initializeTranslateService();
	}

	ngOnInit() {
		if (environment.staging) this.themeService.enableStagingMode();
	}

	public initializeApp() {
		if (this.deviceService.isMobile()) {
			App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
				this.zone.run(() => {
					const domain = 'my.buildmate.be';
					const pathArray = event.url.split(domain);
					const appPath = pathArray.pop();
					if (appPath) {
						this.router.navigateByUrl(appPath);
					}
				});
			});
		}

		// Remove the chosen period when the app is paused or refreshed or closed
		// This to prevent opening the app in the past and the sending the employee to a wrong job, ...
		this.platform.pause.subscribe(() => {
			localStorage.removeItem(_plannerMobileChosenPeriod);
		});
		window.addEventListener('beforeunload', () => {
			localStorage.removeItem(_plannerMobileChosenPeriod);
		});
	}
}
