//////////////////////
// Global constants //
//////////////////////

export const NOP: ((...args: any[]) => void) = (() => {});
export const secPerDay = 24 * 60 * 60;
export const msPerDay = secPerDay * 1000;

//////////////////
// Global types //
//////////////////

export type Nullable<T> = T | null;
export type Optional<T> = T | undefined;
export type Nullish<T> = T | null | undefined;

export type Char = string;
export type Id = number;
export enum YN { kYes = 'Y', kNo = 'N' }

export type Timer = ReturnType<typeof setTimeout>;


export interface Loader {
  [button: string]: boolean;
}

export type DateTime = Date;

export class ServerError extends Error {
  status: number | string;
  statusText: string;
  response?: ServerResponse;
  constructor(message: string, status: number | string, response?: ServerResponse) {
    super(message);
    this.statusText = message;
    this.status = status;
    this.response = response;
  }
}

///////////////////
// Blue Specific //
///////////////////

export enum VATRate { vat21 = '21', vat6 = '6', vat0 = '0' }
export enum Taxed { normal = 'normal', intra = 'intra', shifted = 'shifted' }

// The content of the result of a send-mail depends on each mail system's reply (smtp, ses, gmail, ...)
// Nodemailer (who returns this) also defines it as "any"
export type SentMessageInfo = any;

////////////////////
// HTTP responses //
////////////////////
export enum ServerStatus {
  kOK = 'OK', kNOK = 'NOK', kError = 'ERROR',
  kDone = 'DONE', kEJS = 'EJS',
  kEvent = 'EVENT', kFile = 'FILE',
  kCalendar = 'CALENDAR'
}

export const kErrorServerStatuses: ReadonlyArray<ServerStatus> = [
  ServerStatus.kNOK,
  ServerStatus.kError
];

export enum HttpStatusCode {
  kOK = 200,
  kCreated = 201,
  kNoContent = 204,
  kTemporaryRedirect = 307,
  kPermanentRedirect = 308,
  kNotModified = 304,
  kBadRequest = 400,
  kNotAuthenticated = 401,
  kPaymentRequired = 402,
  kForbidden = 403,
  kNotfound = 404,
  kConflict = 409,
  kGone = 410,
  kPreConditionFailed = 412,
  kTooManyRequests = 429,
  kInternalError = 500,
  kServiceUnavailable = 503
}

export const kEmptyHttpStatusCodes: ReadonlyArray<HttpStatusCode> = [
  HttpStatusCode.kNoContent,
  HttpStatusCode.kTemporaryRedirect,
  HttpStatusCode.kPermanentRedirect,
  HttpStatusCode.kNotModified
];

export const kOKHttpStatusCodes: ReadonlyArray<HttpStatusCode> = [
  HttpStatusCode.kOK,
  HttpStatusCode.kCreated,
  ...kEmptyHttpStatusCodes
];

export interface ServerResponse {
  status: ServerStatus;
  message?: string;
  statusCode?: HttpStatusCode;
  code?: string | number;
  view?: string;
  headers?: { [key: string]: string };
  isBase64Encoded?: boolean;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

// response to a succesfull SAVE or DELETE operation
export interface IdResponse {
  status: ServerStatus.kOK;
  statusCode: HttpStatusCode.kOK;
  id: Id;
}
export function resultId(id: Id): IdResponse {
  return {status: ServerStatus.kOK, statusCode: HttpStatusCode.kOK, id};
}


export function isServerResponse(resp: unknown): resp is ServerResponse {
  return (
    typeof resp === 'object' &&
    resp !== null &&
    'status' in resp &&
    Object.values(ServerStatus).includes((resp as ServerResponse).status)
  );
}

export interface IGlobalMessage {
  message: string;
  severity: 'warning' | 'danger' | 'success' | 'info';
  icon?: string;
  days?: number;
}

export interface ITab {
  label: string;
  value?: string;
  icon?: string;
  routerLink?: string;
}

export type TranslatedColumn = {
  key: string;
  value: string;
};

/////////////////////
// Universal types //
/////////////////////
export enum RefType {
  none = 'none',
  address = 'address',
  connection = 'connection',
  invoice = 'invoice',
  quote = 'quote',
  user = 'user',
  supplier = 'supplier',
  client = 'client',
  company = 'company',
  conditions = 'conditions',
  planning = 'planning',
  project = 'project',
  job = 'job',
  calendarfeed = 'calendarfeed',
  comment = 'comment',
  product = 'product',
  stock = 'stock',
  resource = 'resource',
  item = 'item',
  snippet = 'snippet',
  employee = 'employee',
  user_file = 'user_file'
}
export const tables: Record<RefType, string> = {
  none: 'none',
  address: 'addresses',
  connection: 'connections',
  invoice: 'invoices',
  quote: 'quotes',
  user: 'users',
  supplier: 'suppliers',
  client: 'clients',
  company: 'companies',
  conditions: 'companies',
  planning: 'planning',
  project: 'project',
  job: 'job',
  calendarfeed: 'calendarfeeds',
  comment: 'comments',
  product: 'products',
  stock: 'items',
  resource: 'items',
  item: 'items',
  snippet: 'snippets',
  employee: 'employees',
  user_file: 'user_files'
}


export enum Status {
  draft = 'draft',          // busy working
  sent = 'sent',            // sent but unread
  // read = 'read',            // sent and read
  // changes = 'changes',     // changes requested by client
  approved = 'approved',    // client approved for quote - paid for invoices
  denied = 'denied',        // client denied for quote
  paid = 'paid',            // client paid the invoice
  tbi = 'tbi',              // quote needs to be invoiced
  invoiced = 'invoiced',    // quote is fully invoiced
  expired = 'expired'       // marked by daily cron job
  // archived = 'archived'    // kind of deleted
}
export enum SearchStatus {
  all = 'all',
  open = 'open'
}

export enum BookingSearchStatus {
  readyForBooking = 'ready-for-booking'
}

export const AllDocumentStatuses = {
  ...SearchStatus,
  none: '-',
  ...Status
}

export const AllStatuses = {
  ...AllDocumentStatuses,
  none: '-',
  ...BookingSearchStatus
}

export function makeValueAllStatuses(value: Status | SearchStatus): Status | SearchStatus {
  if (Object.values(AllDocumentStatuses).includes(value)) return value;
  return AllDocumentStatuses.all;
}

export enum DocumentSendType {
  mail_hub = 'mail_hub',
  mail_pdf = 'mail_pdf'
}

export enum DocumentType {
  quote = 'quote',
  invoice = 'invoice',
  creditNote = 'credit-note',
  advance = 'advance',
  template = 'template',
  post = 'post',
  job = 'job',
}

export enum LineType {
  item = 'item',      // a leave item line
  break = 'break',    // a break (page, section, line)
  block = 'block'     // a block with sublines
}

//TODO: rename partialU in hideU, partialP in hideP, partialPU in hidePU !!!
//      keep: none: none, all in all
//
//TODO: change enum in database, 
//      migrate current values
//TODO: global search on 'partial' for hub and ejs rendering.
//
export enum LineDisplay {
  all = 'all',                // show everything in the block or line (default)
  partialP = 'partialP',      // for a block: show only the lines in the block, but not their prices
                              // for a line: dont't show the description of the line
  partialU = 'partialU',      // for a block: show only the lines in the block, but not their units
  partialPU = 'partialPU',    // for a block: show only the lines in the block, but not their prices and units

  none = 'none'               // don't show anything in the block
}

export enum LineStyle {
  B = 'B',                   // bold
  I = 'I',                   // italic
  L = 'L',                   // large font
}

export enum LineBreak {
  line = 0,
  page = 1,
  space = 2
}

export enum ImportSidebarCategory {
  articles = 'articles',
  quotes = 'quotes',
  invoices = 'invoices',
  suppliers = 'suppliers',
  templates = 'templates',
  basket = 'basket',
  library = 'library'
}

export enum SortDirectionEnum { kAsc = 'asc', kDesc = 'desc' }

export interface ISearchParams {
  limit?: number;
  offset?: number;
  sortDirection?: SortDirectionEnum;
  q?: string;
}

export interface INavigationLinks {
  label: string;
  icon: string;
  path: string;
  counter?: number;
  fromAssets?: boolean;
  active?: boolean;
}

export enum FilterTime {
  all = 'all',
  today = 'today',
  yesterday = 'yesterday',
  week = 'week',
  previousWeek = 'previous-week',
  month = 'month',
  previousMonth = 'previous-month'
}

export interface IDropdownOptions<T> {
  value: T;
  label: string;
}

export interface RegistrationMethod {
  name: string;
  value: string;
}

export enum WeekDay {
  kMonday = 'monday',
  kTuesday = 'tuesday',
  kWednesday = 'wednesday',
  kThursday = 'thursday',
  kFriday = 'friday',
  kSaturday = 'saturday',
  kSunday = 'sunday'
}

export enum CancelReasons {
  missing_features = 'missing_features',
  other = 'other',
  too_complex = 'too_complex',
  too_expensive = 'too_expensive',
  missing_supplier = 'missing_supplier',
  timing = 'timing'
}
